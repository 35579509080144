/*
 * Global CSS - Farol DS
 *
 * This CSS is imported automatically in the application. CSS module here is important to avoid any build configuration.
 * Attention, don't change the order of the imports below.
 * 1. CSS Reset
 * 2. Farol-DS Globals
 *
 * @andersonba
*/

@import '@farol-ds/tokens/index.css';
@import './reset';
@import './globals';

/* Global overrides by components */
@import './components/dropdown';
