@use 'sass:map';
@use './variables';
@use '../css/mixins.scss' as m;

/*
 * IconButton - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1145%3A57402
*/

@mixin icon-size($size) {
  min-width: $size;
  min-height: $size;
  width: $size;
  height: $size;
}

.kind {
  &plain {
    &,
    &:visited,
    .spinner {
      color: var(--fds-color-base-low-1);
    }

    &:not(.nointeractive) {
      &:hover {
        color: var(--fds-color-base-low-1);
        @include m.background-opacity(
          var(--fds-color-base-low-3),
          var(--fds-opacity-level-5),
          var(--fds-color-base-high-1)
        );
      }

      &:active {
        color: var(--fds-color-base-low-1);
        @include m.background-opacity(
          var(--fds-color-base-low-3),
          var(--fds-opacity-level-4),
          var(--fds-color-base-high-2)
        );
      }
    }
  }
}

.size,
.kindplain.size {
  &sm,
  &md,
  &lg {
    min-width: auto;
    padding: 0;
  }

  &sm {
    border-radius: var(--fds-border-radius-xs);
  }

  @each $name, $size in variables.$sizes {
    &#{$name} {
      @include icon-size($size);
    }
  }
}
