/*
 * IconList - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3292%3A124577&amp;t=RaQYEippPBkPM6dD-0
*/

.root {
  display: flex;
  flex-direction: column;
  font-weight: var(--fds-font-weight-regular);
  line-height: var(--fds-line-height-md);
}

.size {
  &md {
    gap: var(--fds-spacing-5xs);
    font-size: var(--fds-font-size-2xs);

    [data-fds-iconlist-icon] {
      margin-right: var(--fds-spacing-5xs);
    }
  }

  &lg {
    gap: var(--fds-spacing-4xs);
    font-size: var(--fds-font-size-xs);

    [data-fds-iconlist-icon] {
      margin-right: var(--fds-spacing-4xs);
    }
  }
}
