/*
 * SelectionCardBodyIcon - Farol DS
 * https://www.figma.com/design/QmFJQRbQfkP6Upr9TNreoO/branch/JxPEDKGTkBL604V7Rmgx5V/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?node-id=8446-8102&amp;node-type=canvas&amp;t=LTNIOKGEZSjMJbD0-0
*/

.root {
  display: flex;
  align-items: center;
  color: var(--fds-color-base-low-pure);

  &.vertical {
    align-items: flex-start;
  }
}
