/*
 * File - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2486%3A122501
*/

.root {
  display: flex;
  align-items: center;
  padding: var(--fds-spacing-4xs) var(--fds-spacing-3xs);
  min-height: 48px;
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-4);
  border-radius: var(--fds-border-radius-sm);
  width: 100%;
  text-decoration: none;

  /* animation */
  transition-property: background, border;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  &:not(.disabled):not(.selected) {
    &:hover {
      background-color: var(--fds-color-base-high-1);
      border-color: var(--fds-color-base-high-2);
    }

    &:active {
      background-color: var(--fds-color-base-high-2);
      border-color: var(--fds-color-base-high-2);
    }
  }
}

.variant {
  &neutral {
    .icon {
      color: var(--fds-color-brand-primary-pure);
    }
  }

  &pdf {
    .icon {
      color: var(--fds-color-feedback-negative-pure);
    }
  }

  &doc {
    .icon {
      color: var(--fds-color-feedback-info-pure);
    }
  }
}

.icon {
  margin-right: var(--fds-spacing-3xs);
}

.rightIcon {
  color: var(--fds-color-base-low-2);
  margin-left: var(--fds-spacing-3xs);
}

.selected {
  background-color: var(--fds-color-base-low-1);
  border-color: var(--fds-color-base-low-1);

  .icon {
    color: var(--fds-color-base-high-pure);
  }

  .rightIcon {
    color: var(--fds-color-base-high-2);
  }

  &:hover {
    background-color: var(--fds-color-base-low-2);
    border-color: var(--fds-color-base-low-2);
  }
  &:active {
    background-color: var(--fds-color-base-low-pure);
    border-color: var(--fds-color-base-low-pure);
  }
}

.disabled {
  border-color: var(--fds-color-base-high-1);

  .icon,
  .rightIcon {
    color: var(--fds-color-base-high-4);
  }

  &.selected {
    background-color: var(--fds-color-base-high-1);
    border-color: var(--fds-color-base-high-1);
  }
}

/* clickable */
a.root,
button.root,
.clickable {
  cursor: pointer;

  &.nointeractive {
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
  }
}
