@import '@farol-ds/tokens/index.scss';

.list {
  display: grid;
  gap: var(--fds-spacing-3xs);
}

.card {
  flex-direction: column;
  height: 100%;
  gap: var(--fds-spacing-5xs);
  display: flex;
  align-items: center;
}

.image {
  width: 56px;
  height: 56px;
}

.title {
  font-size: var(--fds-font-size-xs);
}

.error {
  margin-bottom: var(--fds-spacing-3xs);
}

@media (min-width: $breakpoint-sm) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
}
