/*
 * SelectionCardBodyContentTitle - Farol DS
 * https://www.figma.com/design/QmFJQRbQfkP6Upr9TNreoO/branch/JxPEDKGTkBL604V7Rmgx5V/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?node-id=8446-8102&amp;node-type=canvas&amp;t=LTNIOKGEZSjMJbD0-0
*/

.root {
  color: var(--fds-color-base-low-pure);
  font-family: var(--fds-font-family-base);
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-regular);
  line-height: var(--fds-line-height-md);
}
