/*
 * Information - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=7431%3A98494&mode=design&t=uQXrQWC6FgYb1CYW-1
*/

$margin: var(--fds-spacing-6xs);
$z-index-popover: 9010;

.root {
  all: unset;
  cursor: pointer;
  display: inline;
  vertical-align: middle;
  text-align: center;
  border-radius: var(--fds-border-radius-xs);

  &.top {
    margin-bottom: $margin;
  }
  &.right {
    margin-left: $margin;
  }
  &.bottom {
    margin-top: $margin;
  }
  &.left {
    margin-right: $margin;
  }

  &:hover .icon {
    color: var(--fds-color-base-low-1);
  }

  &.onColor {
    & .icon,
    &:hover .icon {
      color: var(--fds-color-base-high-pure);
    }

    &:hover .icon {
      opacity: var(--fds-opacity-level-1);
    }
  }
}

.icon {
  display: block;
  text-align: center;
  color: var(--fds-color-base-low-2);
  transition-property: fill, opacity;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
}

.popover {
  max-width: 300px;
  z-index: $z-index-popover;
}
