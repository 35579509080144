/*
 * TreeViewLink - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3531-151523&amp;t=JF6Vpx1j82Eiswco-0
*/

.item {
  /* extended from BodyText */
  font-weight: var(--fds-font-weight-regular);
  line-height: var(--fds-line-height-lg);
  font-size: var(--fds-font-size-2xs);

  &Selected {
    font-weight: var(--fds-font-weight-semibold);
  }
}

.togglerSelected {
  color: var(--fds-color-brand-primary-pure);
}
