@import '@farol-ds/tokens/index.scss';

/*
 * TopbarUserMenuUnlogged - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/Farol-DS---%5BWeb%5D-Core-Components?node-id=2427%3A32083&t=F0jPrZtuBGRYhKCc-0
*/

.root {
  display: none;
  gap: var(--fds-spacing-4xs);
  margin-left: var(--fds-spacing-4xs);
}

@media (min-width: $breakpoint-md) {
  .root {
    display: inline-flex;
  }
}
