@mixin link-ghost-style {
  color: inherit;
  text-decoration: inherit;

  &:hover {
    text-decoration: underline;
  }
  &:hover,
  &:visited,
  &:visited:hover {
    color: inherit;
  }
}
