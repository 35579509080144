/*
 * AccordionHeader - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=7092-210877&mode=design&t=kwY4Ref3EGUQ1TGr-11
*/

.root {
  display: flex;

  &.size {
    &xl .trigger {
      padding-block: var(--fds-spacing-2xs);
    }
  }
}

.trigger {
  // button reset
  all: unset;

  padding-block: var(--fds-spacing-3xs);

  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  color: var(--fds-color-base-low-pure);
  border-radius: var(--fds-border-radius-sm);
  gap: var(--fds-spacing-3xs);

  &[data-state='open'] .chevron {
    transform: var(--fds-rotate-md);
  }
}

.content {
  flex: 1;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: 'icon title' 'help help';
  justify-content: start;
}

.chevron {
  transition-duration: var(--fds-duration-medium-1);
  transition-timing-function: var(--fds-ease-expressive);
  color: var(--fds-color-base-low-pure);
}
