@use './variables';

/*
 * Icon - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=51%3A5944
*/

.root {
  color: inherit;
}

.size {
  @each $name, $size in variables.$sizes {
    &#{$name} {
      min-width: $size;
      min-height: $size;
      width: $size;
      height: $size;
    }
  }
}
