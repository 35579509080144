/*
 * AutocompleteList - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3349-128843&amp;t=KcYW6wA6FA8I0CDR-0
*/

$z-index-autocomplete-list: 1000;

.root {
  margin: 0;
  background-color: var(--fds-color-base-high-pure);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  position: absolute;
  width: 100%;
  z-index: $z-index-autocomplete-list;

  & > [role='option'] {
    scroll-margin: var(--fds-spacing-5xs);

    &[data-hidden] {
      display: none;
    }
  }
}

.type {
  &primary {
    border-top: var(--fds-border-width-sm) solid var(--fds-color-base-high-1);
    padding: var(--fds-spacing-5xs) var(--fds-spacing-4xs);
  }

  &secondary {
    padding: var(--fds-spacing-5xs);
    margin-top: var(--fds-spacing-6xs);
    border: var(--fds-border-width-sm) solid var(--fds-color-base-high-3);
    border-radius: var(--fds-border-radius-sm);
    box-shadow: var(--fds-shadow-level-4);
    max-height: 255px;
  }
}
