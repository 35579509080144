@use './variables';

/*
 * RadioIndicator - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1219%3A76673
*/

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  animation-name: show;
  animation-duration: variables.$animation-duration;
  animation-timing-function: variables.$animation-timing;
  animation-fill-mode: forwards;

  &::after {
    content: '';
    display: block;
    background-color: var(--fds-color-brand-primary-pure);
    border-radius: var(--fds-border-radius-circle);
    height: 12px;
    width: 12px;
  }

  &[data-disabled]::after {
    background-color: var(--fds-color-base-high-2);
  }
}

@keyframes show {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
