@import '@farol-ds/tokens/index.scss';

/*
 * Responsive global styles for Dropdown component
*/

$z-index-dropdown: 9000;

/* Disable overlay for > mobile */
@media (min-width: $breakpoint-sm) {
  [data-fds-dropdown-menu-overlay] {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
  }
}

/*
 * Override radix-ui dropdown for mobile
 * see __identifyRadixDropdownPortal function in ../../dropdown/utils.tsx
*/
@media (max-width: $breakpoint-sm) {
  [data-fds-dropdown-portal] {
    $z-index: $z-index-dropdown;
    bottom: 0;
    width: 100%;
    /* override radix-ui inline styles, since our mobile version is a drawer */
    top: initial !important;
    transform: unset !important;
    min-width: auto !important;
  }
}
