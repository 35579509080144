@use './variables';
@import '@farol-ds/tokens/index.scss';

/*
 * Timeline - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2882%3A130867
*/

@mixin reposition($size) {
  padding-left: calc($size / 2);
  margin-left: calc($size / 2 * -1);
}

.root {
  direction: ltr;
  list-style: none;
  margin: 0;
  margin-left: calc(variables.$dot-max-size-sm / 2);
  padding: 0;
  position: relative;

  &::before,
  &.endless::after {
    content: '';
    position: absolute;
    left: variables.$line-size * -1;
    width: variables.$line-size;
    bottom: 0;
  }

  &::before {
    top: 0;
    background-color: var(--fds-color-base-high-3);
  }

  &.endless::after {
    background-color: white;
    height: calc(#{var(--fds-spacing-5xs)} + 2px);
  }
}

.unknownItem {
  @include reposition(variables.$dot-max-size-sm);
}

@media (min-width: $breakpoint-sm) {
  .unknownItem {
    @include reposition(variables.$dot-max-size-md);
  }
}
