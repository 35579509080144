.title {
  &a:visited, // for asChild
  a:visited {
    &,
    &:hover {
      color: var(--fds-extra-color-visited);
    }
  }
}

.caption {
  display: block;

  & > *:not(:last-child) {
    margin-right: var(--fds-spacing-6xs);
  }
}

.captionSeparator {
  pointer-events: none;
  user-select: none;

  &::before {
    content: '•';
  }
}
