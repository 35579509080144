/*
 * CheckboxContent - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3213%3A103092&amp;t=uiCRBFHLDxTn4uPF-0
*/

.root {
  display: flex;
  flex-direction: column;
  gap: var(--fds-spacing-6xs);
  padding-left: var(--fds-spacing-5xs);
}
