/*
 * MenuItemLeading - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1229%3A106110
*/

.root {
  display: flex;
  align-items: center;
  margin-right: var(--fds-spacing-4xs);
  fill: inherit;
  color: inherit;

  & > * {
    fill: inherit;
    color: inherit;
  }
}
