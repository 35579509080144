@import '@farol-ds/tokens/index.scss';

/*
 * DrawerContent - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2348%3A117913
*/

$z-index-drawer: 9000;

.root {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: var(--fds-color-base-high-pure);
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  z-index: $z-index-drawer;

  /* animation */
  transition: opacity, transform;
  transition-duration: var(--fds-duration-medium-2);
  transition-timing-function: var(--fds-ease-expressive);
  opacity: 0;

  &.placement {
    &left {
      transform: translateX(-200px);
      left: 0;
    }

    &right {
      transform: translateX(200px);
      right: 0;
    }
  }

  &Visible.placement {
    &left,
    &right {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@media (min-width: $breakpoint-sm) {
  .size {
    &sm {
      width: 360px;
    }

    &md {
      width: 560px;
    }

    &lg {
      width: 720px;
    }
  }
}
