@use 'sass:map';
@import '@farol-ds/tokens/index.scss';

$arrow-element-width: 44px; // same width of icon button MD
$fade-element-width: 12px;

.root {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.boundary {
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: var(--fds-spacing-3xs);
  padding-right: var(--fds-spacing-3xs);

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: $breakpoint-sm) {
    padding-left: 0;
    padding-right: 0;
  }

  &.disableGutters {
    padding-left: 0;
    padding-right: 0;
  }
}

.list {
  display: flex;
  gap: var(--fds-spacing-5xs);
  width: max-content;
  /*
   * [info]
   * padding to support `farol-ds` elements focus outline style
   */
  padding-top: var(--fds-spacing-6xs);
  padding-bottom: var(--fds-spacing-6xs);
}

.arrowLeft,
.arrowRight {
  background-color: var(--fds-color-base-high-pure);
  position: absolute;
  z-index: 1;
  transition-property: opacity;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  &::before {
    content: '';
    width: $fade-element-width;
    height: 100%;
    position: absolute;
  }
}

.arrowLeft {
  left: 0;

  &::before {
    left: $arrow-element-width;
    background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      #{var(--fds-color-base-high-pure)} 100%
    );
  }
}

.arrowRight {
  right: 0;

  &::before {
    right: $arrow-element-width;
    background-image: linear-gradient(
      270deg,
      #{var(--fds-color-base-high-pure)} 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

@mixin showArrow() {
  opacity: 1;
  pointer-events: initial;
  visibility: initial;
}

/* force show right arrow on SM (tablet) breakpoint to avoid CLS */
@media (min-width: $breakpoint-sm) and (max-width: 1439px) {
  .arrowRight:not([data-arrow-visible]) {
    @include showArrow();
  }
}

@media (min-width: $breakpoint-sm) {
  /* show on hook changing */
  .arrowRight[data-arrow-visible='1'],
  .arrowLeft[data-arrow-visible='1'] {
    @include showArrow();
  }
}
