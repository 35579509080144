@import '@farol-ds/tokens/index.scss';

/*
 * ProgressLoader - Farol DS
*/

$z-index-progress: 9000;
$height: 2px;
$width: 100%;

.root {
  display: none;
  overflow: hidden;
  height: $height;
  width: $width;
  pointer-events: none;

  &.active {
    display: block;
  }

  &.top,
  &.bottom {
    position: fixed;
    left: 0;
    z-index: $z-index-progress;
  }
  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
  }
}

.progress {
  width: $width;
  height: $height;
  background-color: var(--fds-color-brand-primary-pure);
  animation: progress-animation 2s var(--fds-ease-expressive) infinite;
  box-shadow: 0 0 2px var(--fds-color-brand-primary-2);
}

@keyframes progress-animation {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
