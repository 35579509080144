@use 'sass:map';
@use '../variables';
@import '@farol-ds/tokens/index.scss';

/*
 * TopbarNav - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/Farol-DS---%5BWeb%5D-Core-Components?node-id=2427%3A32083&t=F0jPrZtuBGRYhKCc-0
*/

$arrow-element-width: 44px; // same width of icon button MD
$fade-element-width: 12px;

.root {
  flex: 1;
  display: flex;
  align-items: center;
  min-height: variables.$topbar-nav-height;
  width: 100%;
  position: relative;
}

.menu {
  gap: var(--fds-spacing-6xs);
  width: max-content;
}

.dropdownItemLink:hover {
  text-decoration: none;
}
