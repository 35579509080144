@import '@farol-ds/tokens/index.scss';
/*
 * DrawerFooter - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2348%3A117913
*/

.root {
  padding: var(--fds-spacing-3xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--fds-spacing-5xs);

  & > * {
    width: 100%;
  }
}

@media (min-width: $breakpoint-sm) {
  .root {
    flex-direction: row-reverse;
    justify-content: flex-start;
    gap: var(--fds-spacing-4xs);

    & > * {
      width: auto;
    }
  }
}
