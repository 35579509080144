/*
 * SelectionCardBody - Farol DS
 * https://www.figma.com/design/QmFJQRbQfkP6Upr9TNreoO/branch/JxPEDKGTkBL604V7Rmgx5V/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?node-id=8446-8102&amp;node-type=canvas&amp;t=LTNIOKGEZSjMJbD0-0
*/

.root {
  flex: 1;
  display: flex;

  &.horizontal {
    flex-direction: row;
    gap: var(--fds-spacing-3xs);
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--fds-spacing-4xs);
  }
}

.disabled {
  opacity: var(--fds-opacity-level-3);
}
