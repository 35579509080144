/*
 * PopoverContentFooter - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3431-135266&amp;t=Die319SGxkzYFN1o-0
*/

.root {
  display: flex;
  margin-top: var(--fds-spacing-3xs);
  align-items: center;
  justify-content: flex-end;
}
