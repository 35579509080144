/*
 * Subtitle - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=911%3A66480
*/

.root {
  line-height: var(--fds-line-height-lg);
  font-weight: var(--fds-font-weight-regular);
  color: var(--fds-color-base-low-pure);
}

.size {
  &md {
    font-size: var(--fds-font-size-sm);
  }
}
