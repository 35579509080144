/*
 * SwitchItem - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&amp;node-id=7279-209249&amp;mode=design&amp;t=gjHB6MlU1QyBFgq5-0
*/

.inline {
  display: inline;
  vertical-align: middle;
}

.root {
  display: inline-flex;
}
