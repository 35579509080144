/*
 * TopbarUserMenuContentProfileSwitch - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/Farol-DS---%5BWeb%5D-Core-Components?node-id=2427%3A32083&t=F0jPrZtuBGRYhKCc-0
*/

.root {
  margin-block: var(--fds-spacing-6xs);
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--fds-spacing-4xs);
  margin-top: var(--fds-spacing-5xs);
}
