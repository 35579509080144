/*
 * PopoverContentClose - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3431-135266&amp;t=Die319SGxkzYFN1o-0
*/

$z-index-popover-content-close: 1;

.root {
  position: absolute;
  top: 0;
  right: 0;
  z-index: $z-index-popover-content-close;
}

/* [FIXME]  FarolDS doens't support transparency button at this moment. @andersonba / @farol-ds-team */
.highlight {
  background-color: transparent !important;
  color: var(--fds-color-base-high-pure) !important;

  /* animation */
  transition-property: opacity;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  &:hover {
    opacity: var(--fds-opacity-level-2);
  }
}
