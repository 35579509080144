/*
 * ModalHeaderDescription - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2778%3A134208
*/

.root {
  &:not(a) {
    color: var(--fds-color-base-low-pure);
  }
}
