@import '@farol-ds/tokens/index.scss';

.brand {
  width: auto;
  height: 20px;
  display: none;

  @media (min-width: $breakpoint-sm) {
    display: block;
  }
}

.brandSymbol {
  width: auto;
  height: 24px;
  display: block;

  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}
