/*
 * PaginationItem - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&amp;node-id=3612-149716&amp;mode=design&amp;t=wYllxpIWdRTrvSYR-0
*/

.root {
  all: reset;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: var(--fds-border-radius-pill);
  text-decoration: none;
  height: 32px;
  min-width: 32px;
  padding-right: var(--fds-spacing-6xs);
  padding-left: var(--fds-spacing-6xs);
  cursor: pointer;
  background-color: transparent;

  transition-property: background-color, color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  .number {
    color: var(--fds-color-base-low-1);
    font-family: var(--fds-font-family-base);
    font-size: var(--fds-font-size-2xs);
    font-weight: var(--fds-font-weight-semibold);
    line-height: var(--fds-line-height-xs);
  }

  &:hover {
    background-color: var(--fds-color-base-high-1);
  }

  &:active {
    background-color: var(--fds-color-base-high-2);
  }
}

.current {
  background-color: var(--fds-color-brand-primary-pure);

  &:hover {
    background-color: var(--fds-color-brand-primary-3);

    .number {
      color: var(--fds-color-base-high-pure);
    }
  }

  &:not(:hover) {
    .number {
      color: var(--fds-color-base-high-pure);
    }
  }
}

.truncation {
  background-color: transparent;
  pointer-events: none;
  cursor: default;

  &:hover,
  &:focus,
  &:active {
    appearance: none;
    background-color: transparent;
    outline: none;
  }
}
