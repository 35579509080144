/*
 * Toast - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3530-151650&amp;t=b5H5jAfym489e1iL-0
*/

$z-index-toast-content: 1;

.root {
  position: relative;
  background-color: var(--fds-color-base-low-pure);
  border-radius: var(--fds-border-radius-sm);
  box-shadow: var(--fds-shadow-level-5);
  width: 100%;
  max-width: 328px;
  padding: var(--fds-spacing-6xs);
  padding-left: var(--fds-spacing-4xs);
  overflow: hidden;

  /* text */
  color: var(--fds-color-base-high-pure);
  font-family: var(--fds-font-family-base);
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-regular);
  line-height: var(--fds-line-height-md);

  /* drag animation */
  &[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  &[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform var(--fds-duration-medium-1) ease-out;
  }

  &[data-swipe='end'] {
    animation: slide-right var(--fds-duration-medium-1) ease-out;
  }
}

.type {
  &negative {
    background-color: var(--fds-color-feedback-negative-pure);
  }
}

.content {
  display: flex;
  align-items: center;
  position: relative;
  z-index: $z-index-toast-content;
}

.icon {
  margin-right: var(--fds-spacing-5xs);
}

.message {
  flex: 1;

  & > a,
  & > a:hover {
    color: var(--fds-color-base-high-pure);
  }
}

.loadingLayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  background-color: var(--fds-color-base-high-pure);
  opacity: var(--fds-opacity-level-4);
  animation: var(--fds-toast-duration) linear forwards loading-effect;
}

.closeBtn {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-property: opacity;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  border-radius: var(--fds-border-radius-sm);
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;

  margin-left: var(--fds-spacing-4xs);

  &:hover {
    opacity: var(--fds-opacity-level-2);
  }
}

@keyframes loading-effect {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes slide-right {
  from {
    opacity: 1;
    transform: translateX(var(--radix-toast-swipe-end-x));
  }

  to {
    opacity: 0;
    transform: translateX(calc(50% + var(--radix-toast-swipe-end-x)));
  }
}
