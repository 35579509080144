@use './variables';
@import '@farol-ds/tokens/index.scss';

/*
 * SearchAutocompleteMenu - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&amp;node-id=3368-138082&amp;mode=design&amp;t=Rl6yP2JVhUToaBct-0
*/

.typePrimary {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-width: 0;
  margin-top: 0;
  box-shadow: none;
}

@media (max-width: $breakpoint-md) {
  .typePrimary {
    max-height: 100%;
    overflow-y: auto;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
    bottom: 0;
    top: variables.$search-height-md;

    &.sizeLg {
      top: variables.$search-height-lg;
    }
  }
}
