@use './variables';
@import '@farol-ds/tokens/index.scss';

/*
 * BannerAction - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2628%3A128141
*/

.root {
  display: flex;
  margin-top: var(--fds-spacing-3xs);
}

@media (min-width: $breakpoint-md) {
  .root {
    flex-direction: row;
  }
}

@media (min-width: variables.$breakpoint-inline) {
  .root {
    padding-left: var(--fds-spacing-4xs);
    margin-top: 0;
    margin-right: variables.$close-icon-offset;
    align-items: center;
  }
}
