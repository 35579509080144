@import '@farol-ds/tokens/index.scss';

/*
 * ModalBody - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2778%3A134208
*/

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding: var(--fds-spacing-2xs) var(--fds-spacing-3xs);
  position: relative;

  &.disablePadding {
    padding: 0;
    min-height: 72px;
  }
}

@media (min-width: $breakpoint-sm) {
  .root {
    &:not(.disablePadding) {
      padding: var(--fds-spacing-2xs);
    }
  }
}
