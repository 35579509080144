/*
 * MenuItem - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1229%3A106110
*/

/* sub-components */
$help-text: [data-fds-menu-item-help-text];

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  list-style: none;
  margin: 0;
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-pure);
  border-radius: var(--fds-border-radius-sm);
  background-color: var(--fds-color-base-high-pure);
  min-height: 44px;
  padding: var(--fds-spacing-5xs);
  cursor: pointer;

  /* label */
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-semibold);
  line-height: var(--fds-line-height-sm);
  text-align: left;
  display: flex;
  white-space: pre-wrap;

  /* animation */
  transition-property: background-color, color, border-color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  &:hover {
    outline: none;
  }
}

a.root {
  text-decoration: none;
}

.block {
  width: 100%;
}

.type {
  &primary:not(#{$help-text}) {
    color: var(--fds-color-base-low-1);
    color: var(--fds-color-base-low-1);
  }

  &primary:hover {
    background-color: var(--fds-color-base-high-1);
    border-color: var(--fds-color-base-high-1);

    &:not(#{$help-text}) {
      color: var(--fds-color-base-low-pure);
      color: var(--fds-color-base-low-pure);
    }
  }

  &primary:active {
    background-color: var(--fds-color-base-high-2);
    border-color: var(--fds-color-base-high-2);
  }

  &danger:not(#{$help-text}) {
    color: var(--fds-color-feedback-negative-pure);
    color: var(--fds-color-feedback-negative-pure);
  }

  &danger:hover {
    background-color: var(--fds-color-feedback-negative-1);
    border-color: var(--fds-color-feedback-negative-1);

    &:not(#{$help-text}) {
      color: var(--fds-color-feedback-negative-3);
      color: var(--fds-color-feedback-negative-3);
    }
  }

  &danger:active {
    background-color: var(--fds-color-feedback-negative-2);
    border-color: var(--fds-color-feedback-negative-2);
  }
}

.current {
  &,
  &:hover,
  &:active {
    background-color: var(--fds-color-brand-primary-1);
    border-color: var(--fds-color-brand-primary-1);
  }

  &:not(#{$help-text}) {
    color: var(--fds-color-brand-primary-pure);
    color: var(--fds-color-brand-primary-pure);
  }

  &:hover:not(#{$help-text}) {
    color: var(--fds-color-brand-primary-3);
    color: var(--fds-color-brand-primary-3);
  }

  &:active:not(#{$help-text}) {
    color: var(--fds-color-brand-primary-4);
    color: var(--fds-color-brand-primary-4);
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;

  & > * {
    color: var(--fds-color-base-high-4) !important;
    color: var(--fds-color-base-high-4) !important;
  }
}
