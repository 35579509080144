/*
 * TreeViewList - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3531-151523&amp;t=JF6Vpx1j82Eiswco-0
*/

.root {
  list-style: none;
  margin: 0;
  padding: 0;

  &:not(.nopadding) {
    padding-left: var(--fds-spacing-6xs);
    margin-left: var(--fds-spacing-2xs);
  }
}
