@import '@farol-ds/tokens/index.scss';

.comment {
  padding-right: var(--fds-spacing-6xs);
  margin-top: var(--fds-spacing-6xs);
  margin-bottom: var(--fds-spacing-3xs);
}

.button {
  width: 100%;
}
