@import '@farol-ds/tokens/index.scss';
/*
 * Textarea - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?t=WNNjX6xp51tnYMVB-0
*/

.root {
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-4);
  border-radius: var(--fds-border-radius-sm);
  padding: var(--fds-spacing-4xs);
  margin: 0;
  width: 100%;

  // animation
  transition-property: border-color, box-shadow;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  color: var(--fds-color-base-low-pure);
  font-size: var(--fds-font-size-xs);
  font-weight: var(--fds-font-weight-regular);
  line-height: var(--fds-line-height-md);

  &::placeholder {
    color: var(--fds-color-base-low-3);
    opacity: 1;
  }

  &.error {
    border-color: var(--fds-color-feedback-negative-pure);
  }

  &[disabled] {
    border-color: var(--fds-color-base-high-1);
    cursor: default;
    pointer-events: none;

    &,
    &::placeholder {
      color: var(--fds-color-base-high-4);
    }
  }
}

@media (min-width: $breakpoint-md) {
  .root {
    font-size: var(--fds-font-size-2xs);
  }
}
