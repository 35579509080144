/*
 * Avatar - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1426%3A86441
*/

$sizes: (
  'sm': 16px,
  'md': 24px,
  'lg': 32px,
  'xl': 48px,
  '2xl': 96px,
);

.root {
  user-select: none;
  border-radius: var(--fds-border-radius-circle);
  text-transform: uppercase;
  font-size: var(--fds-font-size-3xs);
  font-weight: var(--fds-font-weight-semibold);
  line-height: var(--fds-line-height-xs);
  overflow: hidden;

  &,
  .fallback {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }
}

.image {
  object-fit: cover;
  border-radius: inherit;
}

.image,
.fallback {
  width: 100%;
  height: 100%;
}

.fallback {
  color: inherit;
}

.color {
  &primary {
    background-color: var(--fds-color-brand-primary-2);
    color: var(--fds-color-brand-primary-3);
  }

  &secondary {
    background-color: var(--fds-color-brand-secondary-2);
    color: var(--fds-color-brand-secondary-3);
  }

  &tertiary {
    background-color: var(--fds-color-brand-tertiary-2);
    color: var(--fds-color-brand-tertiary-3);
  }
}

.size {
  @each $name, $size in $sizes {
    &#{$name} {
      min-width: $size;
      min-height: $size;
      width: $size;
      height: $size;
    }
  }
}

.size {
  &xl {
    font-size: var(--fds-font-size-xs);
  }

  &2xl {
    font-size: var(--fds-font-size-md);
  }
}
