@use '../css/mixins.scss' as m;
/*
 * TabsTrigger - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2897%3A124639
*/

.root {
  padding: var(--fds-spacing-5xs) var(--fds-spacing-3xs);
  transition-property: background-color, color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  white-space: nowrap;
  border: 0;
  border-radius: var(--fds-border-radius-sm);
  background-color: transparent;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-semibold);
  line-height: var(--fds-line-height-lg);

  min-height: 44px;
  height: 44px;

  &,
  &:hover,
  &:visited,
  &:visited:hover {
    color: var(--fds-color-base-low-1);
  }

  &:hover {
    @include m.background-opacity(
      var(--fds-color-base-low-3),
      var(--fds-opacity-level-5),
      var(--fds-color-base-high-1)
    );
  }

  &:active {
    @include m.background-opacity(
      var(--fds-color-base-low-3),
      var(--fds-opacity-level-4),
      var(--fds-color-base-high-2)
    );
  }

  &[data-state='active'] {
    &,
    &:hover,
    &:visited,
    &:visited:hover {
      color: var(--fds-color-brand-primary-pure);
    }
  }

  &[data-disabled] {
    background-color: inherit;
    pointer-events: none;
    user-select: none;

    &,
    &:hover,
    &:visited,
    &:visited:hover {
      color: var(--fds-color-base-high-4);
    }
  }
}
