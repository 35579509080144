/*
 * Separator - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1205%3A80340
*/

$border-color: var(--fds-color-base-high-3);

.root {
  display: flex;
}

.orientation {
  &horizontal {
    border-top: var(--fds-border-width-sm) solid $border-color;
    width: 100%;
  }

  &vertical {
    border-left: var(--fds-border-width-sm) solid $border-color;
    height: 100%;
  }
}
