@import '@farol-ds/tokens/index.scss';

@keyframes slideDown {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 10rem;
  }
}

.survey {
  width: 100%;
}

.title {
  display: block;
  margin-block: var(--fds-spacing-2xs);
  color: var(--fds-color-base-low-pure);
  font-weight: bold;
}
