/*
 * TreeViewToggler - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3531-151523&amp;t=JF6Vpx1j82Eiswco-0
*/

.root {
  all: unset;
  background-color: transparent;
  transition-property: background, color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  border-radius: var(--fds-border-radius-sm);
  height: 24px;
  width: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  color: var(--fds-color-base-low-3);

  &:hover {
    background-color: var(--fds-color-base-high-1);
  }

  &:active {
    background-color: var(--fds-color-base-high-2);
  }

  &[disabled] {
    &:not(.loading) {
      color: var(--fds-color-base-high-4);
    }

    background-color: transparent;
  }

  &:not([disabled]):not(.loading) {
    cursor: pointer;
  }
}

.expanded {
  .icon {
    transform: var(--fds-rotate-sm);
  }
}

.loading {
  .icon {
    opacity: 0;
  }
}

.icon {
  transition-property: transform;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
