/*
 * TopbarUserMenuContent - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/Farol-DS---%5BWeb%5D-Core-Components?node-id=2427%3A32083&t=F0jPrZtuBGRYhKCc-0
*/

.body {
  gap: var(--fds-spacing-4xs);
  position: relative;
}

.menu {
  gap: var(--fds-spacing-6xs);
}

.userInfo {
  display: flex;
  align-items: center;

  &Text {
    margin-left: var(--fds-spacing-3xs);
    flex: 1;
    min-width: 0;
  }

  &Name {
    font-weight: var(--fds-font-weight-semibold);
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

    &:not(a) {
      color: var(--fds-color-base-low-pure);
    }
  }

  &Link {
    font-size: var(--fds-font-size-2xs);
  }
}

.anonyButtons {
  display: flex;
  gap: var(--fds-spacing-4xs);
  margin: var(--fds-spacing-5xs) 0;
}

.separator {
  width: 100%;
  margin: var(--fds-spacing-6xs) 0;
}
