/*
 * Accordion - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=7092-210877&mode=design&t=kwY4Ref3EGUQ1TGr-11
*/

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}
