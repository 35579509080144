@import '@farol-ds/tokens/index.scss';
/*
 * SelectTrigger - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&node-id=4116-105639&mode=design&t=fbeA0XGOt8oRwhzS-0
*/

$input-height: 44px;
$input-padding-vertical: var(--fds-spacing-5xs);
$input-padding-horizontal: var(--fds-spacing-4xs);

.root {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: $input-padding-vertical $input-padding-horizontal;
  appearance: none;
  outline: none;
  overflow: hidden;
  position: relative;
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-4);
  border-radius: var(--fds-border-radius-sm);
  min-height: $input-height;
  height: $input-height;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--fds-color-base-low-pure);
  font-size: var(--fds-font-size-xs);

  transition-property: border-color, box-shadow;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  &[data-placeholder] {
    color: var(--fds-color-base-low-3);
    opacity: 1;
  }

  &:hover {
    border-color: var(--fds-color-base-low-4);

    &:not(:focus):not([disabled]) {
      box-shadow: var(--fds-shadow-level-1);
    }
  }

  &.error {
    border-color: var(--fds-color-feedback-negative-pure);
  }

  &.disabled {
    border-color: var(--fds-color-base-high-1);

    &,
    .icon {
      color: var(--fds-color-base-high-4);
    }
  }
}

.icon {
  color: var(--fds-color-base-low-1);
  margin-left: var(--fds-spacing-5xs);
}

@media (min-width: $breakpoint-md) {
  .root {
    font-size: var(--fds-font-size-2xs);
  }
}
