/*
 * BodyText - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=911%3A66480
*/

.root {
  font-weight: var(--fds-font-weight-regular);
  line-height: var(--fds-line-height-lg);

  &:not(a) {
    color: var(--fds-color-base-low-2);
  }
}

.size {
  &md {
    font-size: var(--fds-font-size-2xs);
  }

  &lg {
    font-size: var(--fds-font-size-xs);
  }
}
