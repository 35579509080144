@use 'sass:map';
@use '../icon/variables.scss' as icon;

/*
 * Detail - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2708%3A128141
*/

/* same as "iconSize" variable from "./detail-icon.tsx" */
$icon-size: 'sm';

.root {
  display: flex;
  flex-direction: column;
  line-height: var(--fds-line-height-lg);

  [data-fds-detail-value] {
    margin-top: var(--fds-spacing-6xs);
  }
}

.inline {
  display: block;

  [data-fds-detail-icon] {
    top: 2px;
  }

  [data-fds-detail-value] {
    margin-top: 0;
  }

  [data-fds-detail-label] {
    margin-right: var(--fds-spacing-6xs);
  }

  [data-fds-detail-label],
  [data-fds-detail-value] {
    display: inline;
  }
}

.iconed {
  $icon-width: map.get(icon.$sizes, $icon-size);

  position: relative;
  padding-left: calc(#{$icon-width} + #{var(--fds-spacing-5xs)});
}
