@use './variables';
@import '@farol-ds/tokens/index.scss';

/*
 * TimelineItem - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2882%3A130867
*/

$z-index-timeline-item-icon: 1;
$z-index-timeline-item-dot: 1;
$z-index-timeline-item-highlighted-content: -1;
$default-highlighted-delay: 3s;

@mixin reposition($size, $padding) {
  padding-left: calc(#{$size} + #{$padding});
  margin-left: calc(#{$size} * -0.5);
}

@mixin bullet($size) {
  height: $size;
}

@mixin bulletDot($size, $color) {
  height: $size;
  width: $size;
  border-color: $color;
}

.root {
  position: relative;
  padding-top: var(--fds-spacing-3xs);
  padding-bottom: var(--fds-spacing-5xs);
  @include reposition(variables.$dot-max-size-sm, var(--fds-spacing-5xs));
}

.bullet {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: var(--fds-spacing-3xs);
  left: 0;
  width: variables.$dot-max-size-sm;
  @include bullet(variables.$dot-max-size-sm);

  &::before {
    content: '';
    height: variables.$dot-max-size-sm;
    width: variables.$dot-max-size-sm;
    position: absolute;
    display: flex;
    background-color: var(--fds-color-base-high-pure);
    border-radius: var(--fds-border-radius-circle);
  }

  &Icon {
    color: var(--fds-color-brand-primary-pure);

    & > * {
      z-index: $z-index-timeline-item-icon;
    }
  }

  &Dot {
    &::after {
      z-index: $z-index-timeline-item-dot;
      content: '';
      display: inline-flex;
      border-width: var(--fds-border-width-md);
      border-style: solid;
      border-radius: var(--fds-border-radius-circle);
      @include bulletDot(14px, var(--fds-color-brand-primary-pure));
    }
  }
}

.content {
  position: relative;
}

.size {
  &sm {
    padding-top: var(--fds-spacing-5xs);

    .bullet {
      @include bullet(20px);
      top: var(--fds-spacing-5xs);

      &Dot::after {
        @include bulletDot(8px, var(--fds-color-base-low-3));
      }

      &Icon {
        color: var(--fds-color-base-low-3);
      }
    }
  }
}

.highlighted .content::before {
  $offset-x: calc(#{var(--fds-spacing-5xs)} * -1);
  $offset-y: calc(#{var(--fds-spacing-6xs)} * -1);

  content: '';
  position: absolute;
  top: $offset-y;
  left: $offset-x;
  right: $offset-x;
  bottom: $offset-y;
  background: var(--fds-color-brand-primary-1);
  border-radius: var(--fds-border-radius-sm);
  z-index: $z-index-timeline-item-highlighted-content;

  opacity: 1;
  animation: highlight var(--fds-duration-slow-2) var(--fds-ease-expressive);
  animation-delay: var(--fds-timeline-item-highlighted-delay, #{$default-highlighted-delay});
  animation-fill-mode: forwards;
}

@keyframes highlight {
  from {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
    pointer-events: none;
  }
}

@media (min-width: $breakpoint-sm) {
  .root {
    @include reposition(variables.$dot-max-size-md, var(--fds-spacing-3xs));
  }

  .bullet {
    width: variables.$dot-max-size-md;
  }
}
