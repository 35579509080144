.container {
  border: var(--fds-border-width-md) dashed var(--fds-color-base-high-2);
  border-radius: var(--fds-border-radius-sm);
}

.dragOver {
  border-color: var(--fds-color-brand-primary-pure);
}

.error {
  border-color: var(--fds-color-feedback-negative-3);
}
