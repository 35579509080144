@use 'sass:map';
@use '../icon/variables.scss' as icon;

/*
 * DetailValue - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2708%3A128141
*/

.root {
  &:not(a) {
    color: var(--fds-color-base-low-2);
  }
}
