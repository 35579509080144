/*
 * PaginationSimple - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&amp;node-id=3615-171035&amp;mode=design&amp;t=iFAHufs6svPje3Hr-0
*/

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--fds-spacing-4xs);
}

.legend {
  color: var(--fds-color-base-low-3);
  font-size: var(--fds-font-size-2xs);
  line-height: var(--fds-line-height-xs);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--fds-spacing-5xs);
}
