@use './variables';

/*
 * SearchFilter - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3225%3A107400&amp;t=2UpKsobYS1bqmHSS-0
*/

.root {
  all: unset;
  box-sizing: border-box; // [FIXME] remove all:unset above. @andersonba
  cursor: pointer;
  display: flex;
  gap: var(--fds-spacing-6xs);
  align-items: center;
  justify-content: center;
  transition-property: background, color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  border-radius: var(--fds-border-radius-pill);
  padding: var(--fds-spacing-5xs);
  padding-left: var(--fds-spacing-4xs);
  color: var(--fds-color-base-low-1);
  font-size: var(--fds-font-size-2xs);
  line-height: var(--fds-line-height-xs);

  margin-left: calc(-1 * var(--fds-spacing-4xs));

  &Lg {
    font-size: var(--fds-font-size-xs);
    margin-left: calc(-1 * var(--fds-spacing-5xs));
  }

  .icon {
    color: var(--fds-color-base-low-4);
  }

  &:disabled {
    pointer-events: none;
    color: var(--fds-color-base-high-4);

    .icon {
      color: var(--fds-color-base-high-4);
    }
  }

  &:hover {
    background-color: var(--fds-color-base-high-2);

    .icon {
      color: var(--fds-color-base-low-1);
    }
  }

  &:active {
    background-color: var(--fds-color-base-high-3);
  }
}

.separator {
  height: 24px;
  margin-left: var(--fds-spacing-6xs);
  margin-right: var(--fds-spacing-3xs);

  &Lg {
    height: 32px;
    margin-left: var(--fds-spacing-4xs);
    margin-right: var(--fds-spacing-2xs);
  }
}
