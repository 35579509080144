/*
 * Select - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&node-id=4116-105639&mode=design&t=fbeA0XGOt8oRwhzS-0
*/

$z-index-select-content: 9000;

.selectContent {
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-3);
  border-radius: var(--fds-border-radius-sm);
  box-shadow: var(--fds-shadow-level-2);
  max-height: 252px;
  width: var(--radix-select-trigger-width);
  z-index: $z-index-select-content;
}

.selectViewport {
  padding: var(--fds-spacing-5xs);
}
