/*
 * FileContent - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2486%3A122501
*/

.root {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
  justify-content: center;
}

.disabled > * {
  color: var(--fds-color-base-high-4) !important;
}
