/*
 * CollapseControl - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=4541-41931&mode=design&t=TEuL9OaokSUL6vVt-0
*/

$z-index-collapse-control-clamp: 0;
$z-index-collapse-control-button: 1;

$transition-duration: var(--fds-duration-medium-2);
$button-gap: var(--fds-spacing-6xs);
$button-height: 20px;
$showmore-height: calc($button-height + $button-gap);

.root {
  position: relative;

  &::before {
    pointer-events: none;
    content: '';
    position: absolute;
    background: rgb(10, 10, 10);
    background: linear-gradient(
      0deg,
      #{var(--fds-color-base-high-pure)} $showmore-height,
      #ffffff00 100%
    );
    top: -28px;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;

    transition: opacity $transition-duration var(--fds-ease-expressive);
  }

  &Clamp {
    &::before {
      z-index: $z-index-collapse-control-clamp;
      opacity: 1;
    }
  }
}

.icon {
  transition-property: transform;
  transition-duration: $transition-duration;
  transition-timing-function: var(--fds-ease-expressive);

  &Open {
    transform: rotate(-180deg);
  }
}

.button {
  margin-top: $button-gap;
  position: relative;
  z-index: $z-index-collapse-control-button;
}
