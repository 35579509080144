@import '@farol-ds/tokens/index.scss';

/*
 * Sx - Farol DS
*/

// Remember to sync it with config.ts

$spacing-tokens: '6xs', '5xs', '4xs', '3xs', '2xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl',
  '4xl', '5xl', '6xl';
$color-tokens: 'base-low-pure', 'base-low-1', 'base-low-2', 'base-low-3', 'base-low-4',
  'base-high-pure', 'base-high-1', 'base-high-2', 'base-high-3', 'base-high-4', 'brand-primary-pure',
  'brand-primary-1', 'brand-primary-2', 'brand-primary-3', 'brand-primary-4', 'brand-secondary-pure',
  'brand-secondary-1', 'brand-secondary-2', 'brand-secondary-3', 'brand-secondary-4',
  'brand-tertiary-pure', 'brand-tertiary-1', 'brand-tertiary-2', 'brand-tertiary-3',
  'brand-tertiary-4', 'feedback-negative-pure', 'feedback-negative-1', 'feedback-negative-2',
  'feedback-negative-3', 'feedback-negative-4', 'feedback-positive-pure', 'feedback-positive-1',
  'feedback-positive-2', 'feedback-positive-3', 'feedback-positive-4', 'feedback-info-pure',
  'feedback-info-1', 'feedback-info-2', 'feedback-info-3', 'feedback-info-4',
  'feedback-warning-pure', 'feedback-warning-1', 'feedback-warning-2', 'feedback-warning-3',
  'feedback-warning-4';
$breakpoints: (
  '': $breakpoint-xs,
  'sm': $breakpoint-sm,
  'md': $breakpoint-md,
  'lg': $breakpoint-lg,
  'xl': $breakpoint-xl,
);

// from spacing
@each $bpVal, $bp-value in $breakpoints {
  $bp: if($bpVal != '', '-#{$bpVal}', '');

  @each $token in $spacing-tokens {
    @media (min-width: $bp-value) {
      $properties: (
        ('m', ('margin')),
        ('mt', ('margin-top')),
        ('mr', ('margin-right')),
        ('mb', ('margin-bottom')),
        ('ml', ('margin-left')),
        ('mx', ('margin-inline')),
        ('my', ('margin-top', 'margin-bottom')),
        ('p', ('padding')),
        ('pt', ('padding-top')),
        ('pr', ('padding-right')),
        ('pb', ('padding-bottom')),
        ('pl', ('padding-left')),
        ('px', ('padding-inline')),
        ('py', ('padding-top', 'padding-bottom')),
        ('g', ('gap')),
        ('fb', ('flex-basis'))
      );

      @each $prefixAndProperty in $properties {
        $prefix: nth($prefixAndProperty, 1);
        $properties: nth($prefixAndProperty, 2);

        .#{$prefix}-#{$token}#{$bp} {
          @each $property in $properties {
            #{$property}: var(--fds-spacing-#{$token});
          }
        }

        .#{$prefix}-0#{$bp} {
          @each $property in $properties {
            #{$property}: 0;
          }
        }
      }
    }
  }
}

// from colors
@each $bpVal, $bp-value in $breakpoints {
  $bp: if($bpVal != '', '-#{$bpVal}', '');

  @each $token in $color-tokens {
    @media (min-width: $bp-value) {
      $properties: (('c', 'color'), ('bg', 'background-color'), ('f', 'fill'));

      @each $prefixAndProperty in $properties {
        $prefix: nth($prefixAndProperty, 1);
        $property: nth($prefixAndProperty, 2);

        .#{$prefix}-#{$token}#{$bp} {
          #{$property}: var(--fds-color-#{$token});
        }
      }
    }
  }
}

// from native
@each $bpVal, $bp-value in $breakpoints {
  $bp: if($bpVal != '', '-#{$bpVal}', '');

  @media (min-width: $bp-value) {
    $properties: (
      ('ac', 'align-content'):
        (
          'center',
          'flex-start',
          'flex-end',
          'space-between',
          'space-around',
          'space-evenly',
          'stretch',
        ),
      ('ai', 'align-items'):
        (
          'center',
          'flex-start',
          'flex-end',
          'stretch',
        ),
      ('as', 'align-self'):
        (
          'center',
          'flex-start',
          'flex-end',
          'stretch',
        ),
      ('d', 'display'):
        (
          'flex',
          'inline-flex',
          'none',
          'block',
          'inline-block',
        ),
      ('fd', 'flex-direction'):
        (
          'row',
          'column',
          'row-reverse',
          'column-reverse',
        ),
      ('fb', 'flex-basis'):
        (
          'auto',
          '0',
        ),
      ('fg', 'flex-grow'):
        (
          0,
          1,
        ),
      ('fs', 'flex-shrink'):
        (
          0,
          1,
        ),
      ('fw', 'flex-wrap'):
        (
          'wrap',
          'nowrap',
        ),
      ('h', 'height'):
        (
          'auto',
          0,
        ),
      ('jc', 'justify-content'):
        (
          'center',
          'flex-start',
          'flex-end',
          'stretch',
        ),
      ('ji', 'justify-items'):
        (
          'center',
          'flex-start',
          'flex-end',
          'stretch',
        ),
      ('js', 'justify-self'):
        (
          'center',
          'flex-start',
          'flex-end',
          'stretch',
        ),
      ('w', 'width'):
        (
          'auto',
          0,
        ),
    );

    @each $prefixAndProperty, $values in $properties {
      $prefix: nth($prefixAndProperty, 1);
      $property: nth($prefixAndProperty, 2);

      @each $value in $values {
        .#{$prefix}-#{$value}#{$bp} {
          #{$property}: #{$value};
        }
      }
    }

    /* friendly */
    $properties: (
      ('w', 'width', 'full'):
        (
          '100%',
        ),
      ('w', 'width', 'screen'):
        (
          '100vw',
        ),
      ('w', 'width', 'min'):
        (
          'min-content',
        ),
      ('w', 'width', 'max'):
        (
          'max-content',
        ),
      ('w', 'width', 'fit'):
        (
          'fit-content',
        ),
      ('h', 'height', 'full'):
        (
          '100%',
        ),
      ('h', 'height', 'screen'):
        (
          '100vw',
        ),
      ('h', 'height', 'min'):
        (
          'min-content',
        ),
      ('h', 'height', 'max'):
        (
          'max-content',
        ),
      ('h', 'height', 'fit'):
        (
          'fit-content',
        ),
    );

    @each $prefixAndProperty, $values in $properties {
      $prefix: nth($prefixAndProperty, 1);
      $property: nth($prefixAndProperty, 2);
      $name: nth($prefixAndProperty, 3);

      @each $value in $values {
        .#{$prefix}-#{$name}#{$bp} {
          #{$property}: #{$value};
        }
      }
    }
  }
}
