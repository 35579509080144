/*
 * PaginationButton - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3389%3A132789&amp;t=ynNEMsfcF0uYKVZo-0
*/

@mixin button-size($size) {
  min-height: $size;
  min-width: $size;
  height: $size;
  width: $size;
}

.root {
  all: unset;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition-property: background-color, border-color, color, fill;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-4);
  border-radius: var(--fds-border-radius-circle);
  box-shadow: var(--fds-shadow-level-1);
  @include button-size(40px);

  cursor: pointer;

  &,
  &:hover,
  &:visited,
  &:hover:visited {
    color: var(--fds-color-base-low-1);
    color: var(--fds-color-base-low-1);
  }

  &:hover {
    background-color: var(--fds-color-base-high-1);
    border-color: var(--fds-color-base-high-2);
  }

  &:active {
    background-color: var(--fds-color-base-high-2);
    border-color: var(--fds-color-base-high-2);
    box-shadow: none;
  }

  &.disabled,
  &[disabled] {
    border-color: var(--fds-color-base-high-1);
    color: var(--fds-color-base-high-4);
    color: var(--fds-color-base-high-4);
    pointer-events: none;
    cursor: not-allowed;
  }
}
