@use 'sass:map';
@use '../icon/variables.scss' as icon;
@import '@farol-ds/tokens/index.scss';

/*
 * Input - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1281%3A85864
*/

$input-height: 44px;
$input-padding-vertical: var(--fds-spacing-5xs);
$input-padding-horizontal: var(--fds-spacing-4xs);

/* same as "iconSize" variable from "./input.tsx" */
$icon-size: 'sm';

.root {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  min-width: 0;
  appearance: none;
  transition-property: border-color, box-shadow;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-4);
  border-radius: var(--fds-border-radius-sm);
  padding: $input-padding-vertical $input-padding-horizontal;
  min-height: $input-height;
  height: $input-height;

  color: var(--fds-color-base-low-pure);
  font-size: var(--fds-font-size-xs);
  line-height: var(--fds-line-height-xs);

  &::placeholder {
    color: var(--fds-color-base-low-3);
    opacity: 1;
  }

  &:hover {
    border-color: var(--fds-color-base-low-4);

    &:not(:focus):not([disabled]) {
      box-shadow: var(--fds-shadow-level-1);
    }
  }

  &[type='number'] {
    appearance: textfield;

    &:hover,
    &:focus {
      appearance: initial;
    }
  }

  &[type='date']:placeholder-shown {
    appearance: textfield;
  }

  &.error {
    border-color: var(--fds-color-feedback-negative-pure);
  }

  &.hasIcon {
    $icon-width: map.get(icon.$sizes, $icon-size);
    padding-right: calc(#{$input-padding-horizontal} + #{$icon-width} + #{var(--fds-spacing-5xs)});
  }

  &[disabled] {
    border-color: var(--fds-color-base-high-1);

    &,
    &::placeholder {
      color: var(--fds-color-base-high-4);
    }

    & + .rightIcon {
      color: var(--fds-color-base-high-4);
      color: var(--fds-color-base-high-4);
      cursor: default;
      pointer-events: none;
    }
  }
}

.rightIcon {
  position: absolute;
  top: $input-padding-vertical;
  bottom: $input-padding-vertical;
  right: $input-padding-horizontal;
  display: flex;
  align-items: center;
  color: var(--fds-color-base-low-4);
  pointer-events: none;

  &.passwordIcon {
    cursor: pointer;
    pointer-events: initial;
    color: var(--fds-color-base-low-1);
  }

  &.loading {
    background-color: var(--fds-color-base-high-pure);
    padding-left: var(--fds-spacing-5xs);
  }
}

@media (min-width: $breakpoint-md) {
  .input {
    font-size: var(--fds-font-size-2xs);
  }
}
