@import '@farol-ds/tokens/index.scss';

.number {
  margin-bottom: var(--fds-spacing-6xs);
}

.title {
  margin-bottom: var(--fds-spacing-4xs);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.flexDetails {
  margin-bottom: var(--fds-spacing-3xs);
}

.details {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--fds-color-base-low-2);
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress {
  margin-bottom: var(--fds-spacing-4xs);
  margin-right: var(--fds-spacing-4xs);
}

.chipDate {
  margin-bottom: var(--fds-spacing-4xs);
}

.card {
  position: relative;
}

.action {
  align-self: flex-start;
}

.blur {
  filter: blur(10px);
  user-select: none;
}

.buttonContainer {
  display: flex;
  gap: var(--fds-spacing-3xs);
  flex-wrap: wrap;
}

.relatedLawsuitButton {
  margin-left: var(--fds-spacing-5xs);
  display: inline-block;
}

.modalBody {
  gap: var(--fds-spacing-3xs);
}

.modalTitle {
  font-size: var(--fds-font-size-md);
  color: var(--fds-color-base-low-pure);
}

.modalDescription {
  font-size: var(--fds-font-size-xs);
}

.modalLawsuitNumber {
  font-size: var(--fds-font-size-3xs);
  color: var(--fds-color-base-low-2);
}

.modalLawsuitTitle {
  color: var(--fds-color-base-low-pure);
  font-size: var(--fds-font-size-sm);
}

.modalCardContent {
  display: flex;
  justify-content: space-between;
  overflow: visible;
}

.modalLawsuitInfo {
  display: flex;
  flex-direction: column;
}

.modalLawsuitButton {
  display: contents;
}

@media screen and (max-width: $breakpoint-sm) {
  .buttonContainer .lawsuitCardButton {
    width: 100%;
  }
}

@media (min-width: $breakpoint-sm) {
  .title {
    -webkit-line-clamp: 2;
  }

  .lawsuitListAction {
    align-self: flex-start;
  }
}
