@import '@farol-ds/tokens/index.scss';

/*
 * ModalHeader - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2778%3A134208
*/

.root {
  position: relative;
  border-bottom: var(--fds-border-width-sm) solid var(--fds-color-base-high-3);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--fds-spacing-6xs);
  min-height: 64px;
  padding: var(--fds-spacing-3xs);
}

@media (min-width: $breakpoint-sm) {
  .container {
    min-height: 80px;
    padding-block: var(--fds-spacing-2xs);
    padding-inline: var(--fds-spacing-2xs);
  }
}
