@import '@farol-ds/tokens/index.scss';

$z-index-topbar-lgpd: 900;

.root {
  background-color: var(--fds-color-base-high-pure);
  border-top-left-radius: var(--fds-border-radius-md);
  border-top-right-radius: var(--fds-border-radius-md);
  box-shadow: var(--fds-shadow-level-4);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-2);
  padding: var(--fds-spacing-2xs);

  display: flex;
  flex-direction: column;
  gap: var(--fds-spacing-2xs);
  position: fixed;
  width: 100%;
  max-width: 900px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: $z-index-topbar-lgpd;

  animation-name: show;
  animation-duration: var(--fds-duration-medium-2);
  animation-timing-function: var(--fds-ease-expressive);
  animation-fill-mode: forwards;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--fds-spacing-5xs);
}

.title {
  display: flex;
  gap: var(--fds-spacing-6xs);
  align-items: center;
}

@keyframes show {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: $breakpoint-sm) {
  .root {
    flex-direction: row;
    border-radius: var(--fds-border-radius-md);
    bottom: var(--fds-spacing-2xs);
    left: var(--fds-spacing-2xs);
    right: var(--fds-spacing-2xs);
    width: auto;
    padding: var(--fds-spacing-3xs);
    gap: var(--fds-spacing-3xs);
    align-items: center;
  }

  .content {
    flex: 1;
    gap: var(--fds-spacing-6xs);
  }
}
