/*
 * BannerIcon - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2628%3A128141
*/

.root {
  display: inline-flex;
  margin-right: var(--fds-spacing-5xs);
  vertical-align: top;
}
