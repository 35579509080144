@import '@farol-ds/tokens/index.scss';

/*
 * ModalIconClose - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2348%3A117913
*/

$z-index-modal-icon-close: 1;

.root {
  position: absolute;
  top: var(--fds-spacing-5xs);
  right: var(--fds-spacing-5xs);
  z-index: $z-index-modal-icon-close;
}

@media (min-width: $breakpoint-sm) {
  .root {
    top: var(--fds-spacing-3xs);
    right: var(--fds-spacing-3xs);
  }
}
