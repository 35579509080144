@import '@farol-ds/tokens/index.scss';

/*
 * ModalContent - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2348%3A117913
*/

$z-index-modal: 9000;

.root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  display: flex;
  height: 100%;
  flex: 1;
  z-index: $z-index-modal;

  /* animation */
  transition: opacity;
  opacity: 0;

  &Visible {
    opacity: 1;
  }
}

.container {
  display: flex;
  flex: 1;
  margin: auto;
  flex-direction: column;
  background-color: var(--fds-color-base-high-pure);
  box-shadow: var(--fds-shadow-level-5);
  position: relative;
  height: 100%;
  max-width: 100%;
  max-height: 100%;

  /* animation */
  transition: transform;
  transform: translateY(30px);

  &Visible {
    transform: translateY(0);
  }
}

.root,
.container {
  /* animation */
  transition-duration: var(--fds-duration-medium-2);
  transition-timing-function: var(--fds-ease-expressive);
}

.overlay {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: $breakpoint-sm) {
  .root {
    padding: var(--fds-spacing-3xs);
  }

  .container {
    height: auto;
    max-width: 720px;
    border-radius: var(--fds-border-radius-md);
    overflow-y: auto;
  }

  .size {
    &sm .container {
      max-width: 540px;
    }

    &lg .container {
      max-width: 960px;
    }
  }
}
