@import '@farol-ds/tokens/index.scss';

// based on web/libs/shared/farol-ds/react/src/lib/button/button.module.scss
/*
 * Button - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=648%3A13728
*/

.container {
  position: relative;
  width: 102px;
  height: 44px;
  display: none;

  @media (min-width: $breakpoint-md) {
    display: flex;
  }
}

.button {
  // Farol Button
  height: 44px;
  min-width: 56px;
  font-size: var(--fds-font-size-2xs);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  /* text */
  font-weight: var(--fds-font-weight-semibold);
  line-height: var(--fds-line-height-xs);

  /* animation */
  transition-property: background, border, color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  /* cursor */
  cursor: pointer;

  /* shadow */
  &:not(:focus) {
    box-shadow: var(--fds-shadow-level-1);
  }

  &:active {
    box-shadow: none;
  }

  // Custom JusIA styles
  position: relative;
  background: linear-gradient(
    to right,
    var(--fds-color-brand-primary-pure) 0%,
    var(--fds-color-brand-secondary-pure) 100%
  );
  border-radius: var(--fds-border-radius-pill);
  box-shadow: var(--fds-shadow-level-1);
  padding: var(--fds-spacing-4xs) var(--fds-spacing-3xs);
  z-index: 1;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to left,
      var(--fds-color-brand-primary-pure) 0%,
      var(--fds-color-brand-secondary-pure) 100%
    );
    z-index: -1;
    transition-property: opacity;
    transition-duration: var(--fds-duration-medium-2);
    transition-timing-function: var(--fds-ease-productive);
    opacity: 0;
    border-radius: var(--fds-border-radius-pill);
  }

  &:hover::before {
    opacity: 1;
  }

  .icon {
    display: flex;
    min-height: 20px;
    margin-right: var(--fds-spacing-5xs);
  }

  .text {
    font-weight: var(--fds-font-weight-semibold);
    color: var(--fds-color-base-high-pure);
  }
}

.hiddenTrigger {
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: var(--fds-border-radius-pill);
}

.button,
.hiddenTrigger {
  position: absolute;
  top: 0;
  left: 0;
}
