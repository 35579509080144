@use 'sass:map';

/*
 * DetailIcon - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2708%3A128141
*/

.root {
  position: absolute;
  color: var(--fds-color-base-low-pure);
  display: flex;
  align-items: center;
  height: 24px;
  left: 0;
}
