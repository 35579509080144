@import '@farol-ds/tokens/index.scss';

/*
 * Col - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2104%3A99197
*/

.root {
  padding-left: 12px;
  padding-right: 12px;
  width: calc(100% * (1 / 12)); // inline, same of enter 1 for all props
  display: none;
}

@media (min-width: $breakpoint-sm) {
  .root {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@mixin col-media($class, $breakpoint) {
  @for $i from 0 through 12 {
    #{$class}#{$i} {
      @if $i > 0 {
        @media (min-width: $breakpoint) {
          width: calc(100% * (#{$i} / 12));
          display: block;
        }
      } @else {
        @media (min-width: $breakpoint) {
          display: none;
        }
      }
    }
  }
}

/* be careful, order is important here */
@include col-media('.colXs', $breakpoint-xs);
@include col-media('.colSm', $breakpoint-sm);
@include col-media('.colMd', $breakpoint-md);
@include col-media('.colLg', $breakpoint-lg);
@include col-media('.colXl', $breakpoint-xl);
