@import '@farol-ds/tokens/index.scss';

.number {
  margin-bottom: var(--fds-spacing-6xs);
}

.title {
  margin-bottom: var(--fds-spacing-4xs);

  &.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.flexDetails {
  margin-bottom: var(--fds-spacing-3xs);
}

.details {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--fds-color-base-low-2);

  &.truncate {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.progress {
  margin-bottom: var(--fds-spacing-5xs);
}

.card {
  position: relative;
}

.action {
  align-self: flex-start;
}

.buttonContainer {
  display: flex;
  gap: var(--fds-spacing-4xs);
  flex-wrap: wrap;
}

.lawsuitCardButton {
  width: 100%;
}

@media (min-width: $breakpoint-sm) {
  .title {
    &.truncate {
      -webkit-line-clamp: 1;
    }
  }

  .lawsuitListAction {
    align-self: flex-start;
  }

  .buttonContainer {
    gap: var(--fds-spacing-3xs);
  }

  .lawsuitCardButton {
    width: fit-content;
  }
}
