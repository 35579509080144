@import '@farol-ds/tokens/index.scss';

/*
 * SearchAutocompleteMenuErrorMessage - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&amp;node-id=3368-138082&amp;mode=design&amp;t=Rl6yP2JVhUToaBct-0
*/

.root {
  min-height: 44px;
  padding: var(--fds-spacing-5xs);
  display: flex;
  gap: var(--fds-spacing-5xs);
  color: var(--fds-color-feedback-negative-pure);
  align-items: center;
  font-size: var(--fds-font-size-3xs);
}
