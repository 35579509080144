@use '../heading/heading.module.scss';
@use '../link/scss/mixins/ghost-style' as linkMixins;

/*
 * RichText - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=6632%3A148493&mode=design&t=bxFcAY8asvVhJl8e-1
*/

/*
1 - !important is used here to ensure "reset" values due to inline styles found in crawled documents
    ref: https://jusbrasil.slack.com/archives/C06LAUMUBEF/p1709234898451549
*/

/* bodytext (base) */
.richtext {
  --no-follow-icon: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 6C5 5.44772 5.44772 5 6 5H8C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3H6C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V16C21 15.4477 20.5523 15 20 15C19.4477 15 19 15.4477 19 16V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V6ZM14 3C13.4477 3 13 3.44772 13 4C13 4.55228 13.4477 5 14 5H17.5858L11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071L19 6.41421V10C19 10.5523 19.4477 11 20 11C20.5523 11 21 10.5523 21 10V4C21 3.44772 20.5523 3 20 3H14Z' fill='%230467C9'/%3E%3C/svg%3E%0A");

  color: var(--fds-color-base-low-pure);
  font-family: var(--fds-font-family-variant);
  font-size: var(--fds-font-size-sm);
  font-weight: var(--fds-font-weight-regular);
  letter-spacing: var(--fds-letter-spacing-md);
  line-height: var(--fds-line-height-lg);
  hyphens: auto;

  p {
    margin: 0 !important; /* 1 */
    margin-bottom: var(--fds-spacing-3xs) !important; /* 1 */
    text-align: justify;
  }

  strong,
  b {
    font-weight: var(--fds-font-weight-bold) !important; /* 1 */
  }

  a {
    // already defined in globals
  }

  a[data-ghost] {
    @include linkMixins.link-ghost-style;
  }

  a[rel='no-follow'] {
    &::after {
      content: var(--no-follow-icon);
      margin-left: var(--fds-spacing-6xs);
      vertical-align: middle;
    }
  }

  em,
  i {
    font-style: italic;
  }
}

/* heading */
.richtext {
  h1,
  h2,
  h3,
  h4,
  h5 {
    @extend .root;
    font-family: var(--fds-font-family-base);
    margin: 0 !important; /* 1 */
    margin-top: var(--fds-spacing-xs) !important; /* 1 */
    margin-bottom: var(--fds-spacing-5xs) !important; /* 1 */
  }

  h1,
  h2 {
    @extend .sizexl;
  }

  h3 {
    @extend .sizelg;
  }

  h4 {
    @extend .sizemd;
  }

  h5 {
    @extend .sizesm;
  }
}

/* blockquote */
.richtext {
  blockquote {
    margin-block: var(--fds-spacing-2xs);
    margin-left: var(--fds-spacing-xs);
  }
}

/* separator */
.richtext {
  hr {
    margin-block: var(--fds-spacing-xs);
    border: none;

    &::before {
      display: block;
      content: '•••';
      font-size: var(--fds-font-size-3xs);
      line-height: var(--fds-line-height-xs);
      letter-spacing: var(--fds-spacing-2xs);
      text-align: center;
      color: var(--fds-color-base-low-4);
      width: 100%;
    }
  }
}

/* table */
.richtext {
  table {
    font-family: var(--fds-font-family-base);
    width: 100%;
    text-align: left;
    vertical-align: top;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 !important; /* 1 */
    margin-top: var(--fds-spacing-5xs) !important; /* 1 */
    margin-bottom: var(--fds-spacing-xs) !important; /* 1 */

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      vertical-align: inherit;
    }

    td,
    th {
      padding: var(--fds-spacing-5xs) var(--fds-spacing-4xs);
      font-weight: var(--fds-font-weight-regular);
      line-height: var(--fds-line-height-lg);
      font-size: var(--fds-font-size-2xs);
      color: var(--fds-color-base-low-2);
      border-bottom: var(--fds-border-width-sm) solid var(--fds-color-base-high-2);
    }
    thead th,
    tfoot th {
      font-weight: var(--fds-font-weight-semibold);
      font-size: var(--fds-font-size-4xs);
      line-height: var(--fds-line-height-sm);
      text-transform: uppercase;
      color: var(--fds-color-base-low-pure);
    }

    tfoot th {
      border-bottom: none;
    }
  }
}

/* image */
.richtext {
  img {
    background-color: var(--fds-color-base-high-pure);
    border: var(--fds-border-width-sm) solid var(--fds-color-base-high-2);
    border-radius: var(--fds-border-radius-sm);
    padding: var(--fds-spacing-5xs);
  }
}
