@import '@farol-ds/tokens/index.scss';

/*
 * CarouselPagination - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&node-id=3688-153678&mode=design&t=6oqxROj7v1PG7rDq-0
*/

.root {
  align-self: flex-end;
}
