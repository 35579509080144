/*
 * CollapseText - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&amp;node-id=4541-41931&amp;mode=design&amp;t=TEuL9OaokSUL6vVt-0
*/

$button-gap: var(--fds-spacing-6xs);
$button-height: 24px; // 20 (height) + 4 (margin-top), see collapse-control.module.scss
$showmore-height: $button-height;
$transition-delay: 1000ms; // see TRANSITION_DELAY in collapse-text.ts

@mixin animation($properties...) {
  transition-property: $properties;
  transition-duration: var(--fds-duration-medium-2);
  transition-timing-function: var(--fds-ease-expressive);
  transition-delay: $transition-delay;
}

.root {
  position: relative;
  padding-bottom: 0;

  @include animation(padding-bottom);

  &[data-open='true'] {
    padding-bottom: $showmore-height;
  }
}

.trunc {
  overflow: hidden;
  min-height: 0;
  height: 100%;

  @include animation(max-height, height);

  &TextUnclamp {
    display: unset;
    -webkit-box-orient: unset;
    overflow: unset;
    text-overflow: unset;
    line-clamp: unset;
    -webkit-line-clamp: unset;
  }
}

.control {
  position: absolute;
  width: 100%;
  bottom: 0;
  opacity: 0;
  pointer-events: none;

  @include animation(opacity, margin-top);

  &Visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.rehydrated {
  &.root,
  .trunc,
  .control {
    transition-delay: unset;
  }
}
