@use './variables';

/*
 * Radio - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1219%3A76673
*/

.root {
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-md) solid var(--fds-color-base-low-3);
  border-radius: var(--fds-border-radius-circle);
  padding: 0;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;

  &[data-disabled] {
    cursor: default;
  }

  /* animation */
  transition-property: border-color;
  transition-duration: variables.$animation-duration;
  transition-timing-function: variables.$animation-timing;

  &:hover {
    border-color: var(--fds-color-base-low-4);

    /* override RadioIndicator */
    &:not([data-disabled]) > [data-state]::after {
      background-color: var(--fds-color-brand-primary-3);
    }
  }

  &[data-state='checked'] {
    border-color: var(--fds-color-brand-primary-pure);
  }

  &[data-disabled] {
    border-color: var(--fds-color-base-high-2);
  }
}
