/*
 * AccordionIcon - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=7092-210877&mode=design&t=kwY4Ref3EGUQ1TGr-11
*/

.root {
  grid-area: icon;
  display: flex;
  color: var(--fds-color-base-low-pure);
  margin-right: var(--fds-spacing-6xs);
}

.size {
  &xl {
    margin-right: var(--fds-spacing-5xs);
  }
}
