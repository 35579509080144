/*
 * ChipClickable - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2304-111591&amp;t=oQSiCwgq7Uk509ZU-0
*/

$margin-between-md: var(--fds-spacing-6xs);
$margin-between-lg: var(--fds-spacing-6xs);

.root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  transition-property: background-color, border-color, color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-4);
  border-radius: var(--fds-border-radius-pill);
  min-height: 40px;
  padding: var(--fds-spacing-5xs) var(--fds-spacing-4xs);

  color: var(--fds-color-base-low-1);
  font-family: var(--fds-font-family-base);
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-semibold);
  line-height: var(--fds-line-height-sm);
  text-decoration: none;

  &:hover {
    background-color: var(--fds-color-base-high-1);
  }

  &:active {
    background-color: var(--fds-color-base-high-2);
    border-color: var(--fds-color-base-high-2);
  }

  &:hover,
  &:hover:visited,
  &:visited {
    color: var(--fds-color-base-low-1);
  }
}

.selected {
  background-color: var(--fds-color-base-low-pure);
  border-color: var(--fds-color-base-low-1);
  color: var(--fds-color-base-high-pure);

  &:hover {
    background-color: var(--fds-color-base-low-1);
    border-color: var(--fds-color-base-low-2);
  }

  &:active {
    background-color: var(--fds-color-base-low-2);
    border-color: var(--fds-color-base-low-pure);
  }

  &:hover,
  &:hover:visited,
  &:visited {
    color: var(--fds-color-base-high-pure);
  }
}

.leftIcon {
  display: inline-flex;
  margin-right: $margin-between-lg;
  color: inherit;
}

.chevron {
  color: inherit;
  margin-left: $margin-between-lg;

  transition-property: transform;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-expressive);

  &Up {
    transform: var(--fds-rotate-md);
  }
}

.spinner {
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
}

.loading {
  position: relative;
  pointer-events: none;

  .label,
  .leftIcon,
  .chevron {
    opacity: 0;
  }
}

.size {
  &md {
    min-height: 32px;
    padding: var(--fds-spacing-6xs) var(--fds-spacing-4xs);

    .leftIcon {
      margin-right: $margin-between-md;
    }

    .chevron {
      margin-left: $margin-between-md;
    }
  }
}

.disabled {
  pointer-events: none;

  &:not(.selected) {
    color: var(--fds-color-base-high-4);
    border-color: var(--fds-color-base-high-1);
  }

  &.selected {
    background-color: var(--fds-color-base-high-1);
    border-color: var(--fds-color-base-high-1);
    color: var(--fds-color-base-high-4);
  }
}
