/*
 * Menu - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1229%3A106110
*/

.root {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.orientation {
  &vertical {
    flex-direction: column;

    .menuItem:not(:last-child) {
      margin-bottom: var(--fds-spacing-6xs);
    }
  }

  &horizontal {
    flex-direction: row;

    .menuItem:not(:last-child) {
      margin-right: var(--fds-spacing-6xs);
    }
  }
}
