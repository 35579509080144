/*
 * DrawerIconClose - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2348%3A117913
*/

$z-index-drawer-icon-close: 1;

.root {
  position: absolute;
  top: var(--fds-spacing-5xs);
  right: var(--fds-spacing-3xs);
  z-index: $z-index-drawer-icon-close;
}
