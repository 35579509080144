/*
 * PopoverContentDescription - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3431-135266&amp;t=Die319SGxkzYFN1o-0
*/

.highlight {
  &:not(a) {
    color: var(--fds-color-base-high-pure);
  }
}
