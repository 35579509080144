@import '@farol-ds/tokens/index.scss';

/*
 * Prompt - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&node-id=4038-160804&mode=design&t=124sMbMrKKFcILb7-0
*/

$z-index-modal: 9000;

.root {
  background-color: var(--fds-color-base-high-pure);
  max-width: 328px;
  width: calc(100% - var(--fds-spacing-xs));
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: fit-content;
  flex: 1;
  z-index: $z-index-modal;
  margin: auto;
  box-shadow: var(--fds-shadow-level-5);
  border-radius: var(--fds-border-radius-md);
  padding-top: var(--fds-spacing-xs);
  padding-bottom: var(--fds-spacing-2xs);
  padding-inline: var(--fds-spacing-2xs);
}

.buttonContainer {
  display: flex;
  margin-top: var(--fds-spacing-2xs);
  flex-direction: column;
}

.buttonCancel {
  margin-top: var(--fds-spacing-5xs);
}

.description {
  margin-top: var(--fds-spacing-5xs);
  margin-bottom: var(--fds-spacing-4xs);
}

@media (min-width: $breakpoint-sm) {
  .root {
    max-width: 540px;
    display: inline;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row-reverse;
    gap: var(--fds-spacing-4xs);
    float: right;
  }

  .buttonCancel {
    margin-top: 0;
  }
}
