@import '@farol-ds/tokens/index.scss';

/*
 * Container - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2104%3A99197
*/

@mixin spacing($size) {
  padding-left: $size;
  padding-right: $size;
}

.root {
  margin: 0 auto;
  width: 100%;
  max-width: 1312px;
  @include spacing(16px);
}

.type {
  &fluid {
    max-width: unset;
  }

  &letter {
    max-width: 760px;
  }
}

/* Responsive styles */
@media (min-width: $breakpoint-sm) {
  .root:not(.typefluid) {
    @include spacing(32px);
  }
}

@media (min-width: $breakpoint-md) {
  .root:not(.typefluid) {
    @include spacing(48px);
  }

  .type {
    &letter {
      @include spacing(0);
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .root:not(.typefluid) {
    @include spacing(72px);
  }

  .type {
    &letter {
      @include spacing(0);
    }
  }
}

@media (min-width: $breakpoint-xl) {
  .root:not(.typefluid),
  .typeletter {
    @include spacing(0);
  }
}

/* Spacing prop behavior */
@mixin spacingFull() {
  max-width: unset;
  overflow-x: hidden;
  @include spacing(0);
}

@media (min-width: $breakpoint-xs) and (max-width: calc(#{$breakpoint-sm} - 1px)) {
  .root.spacingxs {
    &full {
      @include spacingFull();
    }
  }
}

@media (min-width: $breakpoint-sm) and (max-width: calc(#{$breakpoint-md} - 1px)) {
  .root.spacingsm {
    &full {
      @include spacingFull();
    }
  }
}

@media (min-width: $breakpoint-md) and (max-width: calc(#{$breakpoint-lg} - 1px)) {
  .root.spacingmd {
    &full {
      @include spacingFull();
    }
  }
}

@media (min-width: $breakpoint-lg) and (max-width: calc(#{$breakpoint-xl} - 1px)) {
  .root.spacinglg {
    &full {
      @include spacingFull();
    }
  }
}

@media (min-width: $breakpoint-xl) {
  .root.spacingxl {
    &full {
      @include spacingFull();
    }
  }
}
