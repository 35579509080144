/*
 * BookCover - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=4535-4886&mode=design&t=ETTkviXdl3WWicIo-4
*/

@mixin bordered($radius) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

// [links and buttons] reset asChild
a.root,
button.root {
  border-radius: unset;
  padding: unset;
  border: unset;
  cursor: pointer;

  transition-property: transform, box-shadow, opacity;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  &:hover,
  &:active {
    transform: var(--fds-scale-sm);
    box-shadow: var(--fds-shadow-level-3);
  }

  &:active {
    opacity: var(--fds-opacity-level-1);
  }
}

a.root,
button.root,
.root {
  @include bordered(var(--fds-border-radius-xs));
  height: 156px;
  width: 104px;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

a.size,
button.size,
.size {
  &xs {
    @include bordered(0);
    height: 36px;
    width: 24px;
  }

  &sm {
    height: 108px;
    width: 72px;
  }

  &lg {
    @include bordered(var(--fds-border-radius-sm));
    height: 240px;
    width: 160px;
  }

  &5xl {
    @include bordered(var(--fds-border-radius-md));
    height: 900px;
    width: 600px;
  }
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
