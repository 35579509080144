/*
 * AccordionTitle - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=7092-210877&mode=design&t=kwY4Ref3EGUQ1TGr-11
*/

.root {
  grid-area: title;
  color: var(--fds-color-base-low-pure);
  font-size: var(--fds-font-size-2xs);
  line-height: var(--fds-line-height-sm);
  font-weight: var(--fds-font-weight-regular);
}

.size {
  &lg {
    font-size: var(--fds-font-size-sm);
  }
  &xl {
    font-size: var(--fds-font-size-md);
    font-weight: var(--fds-font-weight-semibold);
  }
}
