/*
 * RadioGroup - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1219%3A76673
*/

.root {
  display: flex;
  align-items: flex-start;
}

.orientation {
  &vertical {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-bottom: var(--fds-spacing-3xs);
    }
  }

  &horizontal {
    flex-direction: row;
    flex-wrap: wrap;
    margin: calc(var(--fds-spacing-3xs) * -1) 0 0 0;

    & > * {
      margin-top: var(--fds-spacing-3xs);
    }

    & > *:not(:last-child) {
      margin-right: var(--fds-spacing-3xs);
    }
  }
}
