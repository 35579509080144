@import '@farol-ds/tokens/index.scss';

/*
 * Heading - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=911%3A66480
*/

.root {
  font-weight: var(--fds-font-weight-semibold);
  line-height: var(--fds-line-height-sm);
  color: var(--fds-color-base-low-pure);
}

.size {
  &sm {
    font-size: var(--fds-font-size-xs);
  }

  &md {
    font-size: var(--fds-font-size-sm);
  }

  &lg {
    font-size: var(--fds-font-size-md);
  }

  &xl {
    font-size: var(--fds-font-size-lg);
  }

  &2xl {
    font-size: var(--fds-font-size-xl);
  }

  &3xl {
    font-size: var(--fds-font-size-2xl);
  }
}

@media (min-width: $breakpoint-md) {
  .size {
    &sm {
      font-size: var(--fds-font-size-xs);
    }

    &md {
      font-size: var(--fds-font-size-md);
    }

    &lg {
      font-size: var(--fds-font-size-lg);
    }

    &xl {
      font-size: var(--fds-font-size-xl);
    }

    &2xl {
      font-size: var(--fds-font-size-2xl);
    }

    &3xl {
      font-size: var(--fds-font-size-3xl);
    }
  }
}
