@use 'sass:map';
@use '../icon/variables.scss' as icon;
@import '@farol-ds/tokens/index.scss';

$padding: var(--fds-spacing-3xs);
$breakpoint-inline: $breakpoint-md;

/* same as "iconSize" variable from "./banner-close.tsx" */
$close-icon-size: 'md';
$close-icon-width: map.get(icon.$sizes, $close-icon-size);
$close-icon-offset: calc(#{$close-icon-width} + #{var(--fds-spacing-3xs)});
