@use './variables';

/*
 * SearchIconButton - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3225%3A107400&amp;t=2UpKsobYS1bqmHSS-0
*/

.root {
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: variables.$icon-size;
  width: variables.$icon-size;
  min-height: variables.$icon-size;
  min-width: variables.$icon-size;
  border-radius: var(--fds-border-radius-xs);
  color: var(--fds-color-base-low-3);
  color: var(--fds-color-base-low-3);

  transition-property: color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover:not(:disabled) {
    color: var(--fds-color-base-low-1);
    color: var(--fds-color-base-low-1);
  }

  &:disabled {
    color: var(--fds-color-base-high-4);
    color: var(--fds-color-base-high-4);
  }
}
