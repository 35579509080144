/*
 * FileHelpText - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2486%3A122501
*/

.root {
  flex: 1;
  color: var(--fds-color-base-low-2);
  font-size: var(--fds-font-size-2xs);
  line-height: var(--fds-line-height-sm);
  margin-top: var(--fds-spacing-6xs);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.selected {
  color: var(--fds-color-base-high-2);
}
