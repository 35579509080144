/*
 * MenuItemHelpText - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1229%3A106110
*/

.root {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-regular);
  line-height: var(--fds-line-height-md);
  margin-top: var(--fds-spacing-5xs);
  color: var(--fds-color-base-low-2);
  text-align: left;
}
