@use './variables';

/*
 * SearchAutocompleteTrigger - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&amp;node-id=3368-138082&amp;mode=design&amp;t=Rl6yP2JVhUToaBct-0
*/

.backIconArea {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  padding: 0 var(--fds-spacing-4xs);
}

.backIcon {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--fds-border-radius-xs);
  color: var(--fds-color-base-low-3);
  cursor: pointer;
  width: variables.$back-icon-size;
  height: variables.$back-icon-size;

  &:hover {
    color: var(--fds-color-base-low-1);
  }
}
