/*
 * AutocompleteItemContent - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3349-128843&amp;t=KcYW6wA6FA8I0CDR-0
*/

.root {
  flex: 1;
  display: flex;
  align-items: center;
  min-height: 44px;
  padding: var(--fds-spacing-6xs) var(--fds-spacing-5xs);
}

.leftIcon {
  margin-right: var(--fds-spacing-5xs);
}

.text {
  flex: 1;

  span,
  b,
  strong {
    font-weight: var(--fds-font-weight-semibold);
  }
}

// ellipsis
.root,
.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
