/*
 * ContextMenuContent - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=6895-56149&mode=design&t=KN0GKdgkJAlNvvZo-0
*/

.root {
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-3);
  border-radius: var(--fds-border-radius-sm);
  box-shadow: var(--fds-shadow-level-3);
  min-width: 220px;
  padding: var(--fds-spacing-5xs);
}
