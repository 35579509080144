/*
 * FormControlErrorMessage - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1281%3A92990
*/

.root {
  color: var(--fds-color-feedback-negative-pure);
  font-size: var(--fds-font-size-3xs);
  line-height: var(--fds-line-height-sm);
  margin-top: var(--fds-spacing-6xs);
}
