/*
 * Collapse - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&amp;node-id=4541-41931&amp;mode=design&amp;t=TEuL9OaokSUL6vVt-0
*/

.content {
  overflow: hidden;

  // animation
  transition-property: max-height;
  transition-duration: var(--fds-duration-medium-2);
  transition-timing-function: var(--fds-ease-expressive);
}
