/*
 * DrawerHeader - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2348%3A117913
*/

.root {
  padding: var(--fds-spacing-5xs) var(--fds-spacing-3xs);
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-height: 60px;
}
