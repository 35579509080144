/*
 * Link - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1214%3A75943
*/

@use './scss/mixins/ghost-style' as m;

@mixin force-link-color($color, $hover-color) {
  /* it override the visited color link, defined by globals */

  &,
  &:visited {
    color: $color;
  }

  &:hover,
  &:visited:hover {
    color: $hover-color;
  }
}

.root:not(.typeghost) {
  transition-property: color, text-decoration-color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  border-radius: var(--fds-border-radius-xs);
  text-decoration: underline;

  &:hover {
    text-decoration: underline rgba(0, 0, 0, 0);
  }
}

.type {
  &primary {
    @include force-link-color(
      var(--fds-color-brand-primary-pure),
      var(--fds-color-brand-primary-3)
    );
  }

  &secondary {
    color: var(--fds-color-feedback-info-pure);

    &:hover {
      color: var(--fds-color-feedback-info-3);
    }
  }

  &tertiary {
    @include force-link-color(var(--fds-color-base-low-2), var(--fds-color-base-low-1));
  }

  &ghost {
    @include m.link-ghost-style;
  }
}
