@use '../variables';
@import '@farol-ds/tokens/index.scss';

/*
 * TopbarHeader - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/Farol-DS---%5BWeb%5D-Core-Components?node-id=2427%3A32083&t=F0jPrZtuBGRYhKCc-0
*/

.root {
  flex: 1;
  display: flex;
  align-items: center;
  min-height: variables.$topbar-header-sm-height;
}

.takeover {
  position: absolute;
  top: var(--fds-spacing-6xs);
  left: var(--fds-spacing-2xs);
}

.separator {
  flex: 1;
}

.ctaContainer {
  display: flex;
  justify-content: flex-end;
  min-width: 180px;
}

.userLeftAddon {
  display: none;
  margin-right: var(--fds-spacing-4xs);
}

.searchInput {
  width: 100%;
}

.autocompleteRoot {
  flex: 8;
  width: 100%;
  max-width: 640px;
}

.autocomplete:not([data-focused]) {
  position: absolute;
  height: 0;
  width: 0;
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}

.themeSwitch {
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--fds-duration-slow-1) var(--fds-ease-expressive);

  &Visible {
    opacity: 1;
    pointer-events: auto;
  }
}

@media (min-width: $breakpoint-md) {
  .autocomplete:not([data-focused]) {
    position: relative;
    height: auto;
    width: auto;
    transform: scale(1);
    opacity: 1;
    pointer-events: auto;
  }
}

@media (min-width: $breakpoint-sm) {
  .userLeftAddon {
    display: inline-flex;
  }
}

@media (min-width: $breakpoint-md) {
  .root {
    min-height: variables.$topbar-header-md-height;
  }

  .searchButton {
    display: none;
  }

  .anonyButtons {
    display: inline-flex;
  }
}
