/*
 * TabsTriggerLeading - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2897%3A124639
*/

.root {
  display: flex;
  align-items: center;
  margin-right: var(--fds-spacing-6xs);
  color: inherit;
}
