/*
 * Badge - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=23%3A2
*/

/* container */
.container {
  position: relative;
  color: inherit;
  fill: inherit;
  display: inline-flex;
  align-items: center;

  .root {
    display: inline-flex;
    align-items: center;
  }

  &Overhead {
    .root {
      position: absolute;
      top: 0;
      right: 0;
    }

    &:not(.circular) .root {
      transform: translate(50%, -50%);
    }
  }
}

/* @type */
.label {
  border-radius: var(--fds-border-radius-pill);
  padding: var(--fds-spacing-6xs);
  text-align: center;

  &Text {
    color: var(--fds-color-base-high-pure);
    font-size: var(--fds-font-size-3xs);
    line-height: var(--fds-line-height-xs);
    font-weight: var(--fds-font-weight-semibold);
    min-width: 12px;
  }
}

.dot {
  border-radius: var(--fds-border-radius-circle);
  height: 8px;
  width: 8px;
}

/* @variant */
.primary {
  background-color: var(--fds-color-brand-primary-pure);
}

.secondary {
  background-color: var(--fds-color-feedback-negative-pure);
}

.tertiary {
  &.label {
    background-color: var(--fds-color-base-high-2);

    .labelText {
      color: var(--fds-color-base-low-2);
    }
  }

  &.dot {
    background-color: var(--fds-color-base-high-3);
  }
}
