/*
 * ChipRemovable - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2304-111591&amp;amp;t=oQSiCwgq7Uk509ZU-0
*/

.root {
  display: inline-flex;
  align-items: center;

  transition-property: background-color, color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  background-color: var(--fds-color-base-high-1);
  border-radius: var(--fds-border-radius-pill);
  min-height: 32px;
  padding-left: var(--fds-spacing-4xs);

  color: var(--fds-color-base-low-1);
  font-family: var(--fds-font-family-base);
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-semibold);
  line-height: var(--fds-line-height-sm);
}

.disabled {
  pointer-events: none;

  &,
  .closeBtn {
    color: var(--fds-color-base-high-3);
  }
}

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--fds-color-base-low-3);
  margin-left: var(--fds-spacing-5xs);
  cursor: pointer;

  border-radius: var(--fds-border-radius-circle);
  transition-property: background-color, color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  min-height: 32px;
  min-width: 32px;
  height: 32px;
  width: 32px;

  &:hover {
    background-color: var(--fds-color-base-high-2);
    color: var(--fds-color-base-low-1);
  }

  &:active {
    background-color: var(--fds-color-base-high-3);
    color: var(--fds-color-base-low-pure);
  }
}
