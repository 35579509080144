/*
 * FormControlDescription - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1281%3A92990
*/

.root {
  color: var(--fds-color-base-low-1);
  font-size: var(--fds-font-size-3xs);
  line-height: var(--fds-line-height-md);
  margin-bottom: var(--fds-spacing-5xs);
}
