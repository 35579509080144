@import '@farol-ds/tokens/index.scss';

/*
 * Pagination - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&amp;node-id=3612-149716&amp;mode=design&amp;t=wYllxpIWdRTrvSYR-0
*/

.root {
  display: flex;
  align-items: center;
  gap: var(--fds-spacing-4xs);
}

.numbers {
  display: none;
  gap: var(--fds-spacing-6xs);
}

.legend {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--fds-color-base-low-1);
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-semibold);
  line-height: var(--fds-line-height-xs);
  flex: 1;
}

@media (min-width: $breakpoint-sm) {
  .root {
    gap: var(--fds-spacing-4xs);
  }

  .numbers {
    display: flex;
  }

  .legend {
    display: none;
  }
}
