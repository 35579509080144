/*
 * Checkbox - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3213%3A103092&amp;t=uiCRBFHLDxTn4uPF-0
*/

$size: 20px;

.root {
  transition-property: border-color, border-width, background-color;
  transition-duration: var(--fds-duration-fast-1);
  transition-timing-function: var(--fds-ease-productive);
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-md) solid var(--fds-color-base-low-3);
  border-radius: var(--fds-border-radius-xs);
  height: $size;
  width: $size;
  min-width: $size;
  min-height: $size;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &[data-disabled] {
    cursor: default;
  }

  &:hover:not([data-disabled]) {
    border-width: var(--fds-border-width-md);
    border-color: var(--fds-color-base-low-4);

    &[data-state='checked'] {
      background-color: var(--fds-color-brand-primary-3);
      border-color: var(--fds-color-brand-primary-3);
    }
  }

  &[data-state='checked'] {
    background-color: var(--fds-color-brand-primary-pure);
    border-color: var(--fds-color-brand-primary-pure);
  }

  &[data-disabled] {
    border-color: var(--fds-color-base-high-2);

    &[data-state='checked'] {
      background-color: var(--fds-color-base-high-2);
    }
  }
}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.icon {
  color: var(--fds-color-base-high-pure);
  display: flex;
}
