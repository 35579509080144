/*
 * TreeViewItem - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3531-151523&amp;t=JF6Vpx1j82Eiswco-0
*/

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 40px;
  border-radius: var(--fds-border-radius-xs);
}

.content {
  display: flex;
  min-height: 40px;
  padding-block: var(--fds-spacing-5xs);
  align-items: flex-start;
  gap: var(--fds-spacing-6xs);
  line-height: var(--fds-line-height-xs);

  &Padded {
    margin-left: 24px;
    padding-left: var(--fds-spacing-6xs);
  }
}

.list {
  padding-left: var(--fds-spacing-6xs);

  &[data-state='open'] {
    animation: slideDown var(--fds-duration-medium-1) var(--fds-ease-expressive);
  }

  &[data-state='closed'] {
    animation: slideUp var(--fds-duration-medium-1) var(--fds-ease-expressive);
  }
}

@keyframes slideDown {
  0% {
    height: 0;
    opacity: 0;
  }

  50% {
    height: var(--radix-collapsible-content-height);
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  0% {
    opacity: 1;
    height: var(--radix-collapsible-content-height);
  }

  50% {
    opacity: 0;
    height: var(--radix-collapsible-content-height);
  }

  100% {
    opacity: 0;
    height: 0;
  }
}
