/*
 * AccordionHelpText - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=7092-210877&mode=design&t=kwY4Ref3EGUQ1TGr-11
*/

.root {
  grid-area: help;
  color: var(--fds-color-base-low-pure);
  font-size: var(--fds-font-size-3xs);
  line-height: var(--fds-line-height-md);
  margin-top: var(--fds-spacing-6xs);
}

.size {
  &lg,
  &xl {
    font-size: var(--fds-font-size-2xs);
  }

  &xl {
    margin-top: var(--fds-spacing-5xs);
  }
}
