/*
 * List - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3292%3A124577&amp;t=RaQYEippPBkPM6dD-0
*/

.unstyled {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
