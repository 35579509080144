@use './variables';

/*
 * BannerIconClose - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2628%3A128141
*/

.root {
  position: absolute;
  top: 0;
  right: 0;
}

.level {
  &app {
    top: 2px;
  }
}
