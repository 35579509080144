@use 'sass:map';
@use '../icon-button/variables.scss' as iconButton;

/*
 * TabsList - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2897%3A124639
*/

$z-index-tabs-list-arrow: 1;

$height: 49px; // 48px + 1px border
/* same as "iconButtonProps.size" variable from "./tabs-list.tsx" */
$button-size: 'md';
/* same as "ARROW_ELEMENT_WIDTH" variable from "./use-tabs-list.tsx" */
$arrow-element-width: map.get(iconButton.$sizes, $button-size); // same width of icon button above
/* same as "FADE_ELEMENT_WIDTH" variable from "./use-tabs-list.tsx" */
$fade-element-width: 12px;

.root {
  position: relative;
  width: 100%;
  display: flex;
}

.content {
  height: $height;
  min-height: $height;
  flex: 1;
  display: flex;
  width: 100%;
  padding-inline: 0;
  padding-top: 0;
  padding-bottom: var(--fds-spacing-6xs);
  position: relative;
  border-bottom: var(--fds-border-width-sm) solid var(--fds-color-base-high-3);

  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.arrowLeft,
.arrowRight {
  background-color: var(--fds-color-base-high-pure);
  position: absolute;
  top: 0;
  bottom: var(--fds-border-width-sm);
  z-index: $z-index-tabs-list-arrow;
  transition-property: opacity;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  &[data-fds-tabs-arrow-visible='1'] {
    opacity: 1;
    pointer-events: initial;
    visibility: initial;
  }

  &::before {
    content: '';
    width: $fade-element-width;
    height: 100%;
    position: absolute;
  }
}

.arrowLeft {
  left: 0;

  &::before {
    left: $arrow-element-width;
    background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      #{var(--fds-color-base-high-pure)} 100%
    );
  }
}

.arrowRight {
  right: 0;

  &::before {
    right: $arrow-element-width;
    background-image: linear-gradient(
      270deg,
      #{var(--fds-color-base-high-pure)} 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.underline {
  position: absolute;
  height: 2px;
  margin-top: var(--fds-border-width-sm);
  bottom: 0;
  left: 0;
  width: 0;
  background-color: var(--fds-color-brand-primary-pure);
  border-top-left-radius: var(--fds-border-radius-xs);
  border-top-right-radius: var(--fds-border-radius-xs);
  transition-property: left width;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
}
