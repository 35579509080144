/*
 * Switch - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&amp;node-id=7279-209249&amp;mode=design&amp;t=gjHB6MlU1QyBFgq5-0
*/
@use '../css/mixins.scss' as m;

.root {
  width: 42px;
  height: 24px;
  background-color: var(--fds-color-base-low-4);
  color: var(--fds-color-base-low-4);
  border-radius: var(--fds-border-radius-pill);
  position: relative;

  transition-property: background-color, color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  &:hover:not(.nointeractive) {
    background-color: var(--fds-color-base-low-2);
    color: var(--fds-color-base-low-2);

    & ~ [data-fds-switch-label] {
      cursor: pointer;
    }
  }
  &:focus {
    @include m.focus-style;
  }
  &[data-state='checked'] {
    background-color: var(--fds-color-brand-primary-pure);
    color: var(--fds-color-brand-primary-pure);

    &:hover:not(.nointeractive) {
      background-color: var(--fds-color-brand-primary-3);
      color: var(--fds-color-brand-primary-3);
    }
  }

  &.nointeractive {
    cursor: not-allowed;
  }

  &.disabled {
    background-color: var(--fds-color-base-high-3);
    color: var(--fds-color-base-high-3);

    & ~ [data-fds-switch-label] {
      color: var(--fds-color-base-high-4);
    }
    .thumb {
      box-shadow: none;
    }
  }

  &.sm {
    width: 32px;
    height: 20px;

    .thumb {
      width: 16px;
      height: 16px;

      &[data-state='checked'] {
        transform: translateX(14px);
      }
    }
  }
  &.md {
    width: 42px;
    height: 24px;

    .thumb {
      width: 20px;
      height: 20px;

      &[data-state='checked'] {
        transform: translateX(20px);
      }
    }
  }
}

.thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--fds-color-base-high-pure);
  border-radius: var(--fds-border-radius-circle);
  position: relative;
  overflow: hidden;
  box-shadow: var(--fds-shadow-level-2);

  transition-property: transform;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  transform: translateX(2px);
  will-change: transform;
}

/* ANIMATION */

.closeIcon,
.checkIcon,
.spinnerIcon {
  position: absolute;
  opacity: 0;
  transform: rotate(0);

  transition-property: opacity, transform;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
}
.root {
  // initial state
  &:not(.loading) {
    .closeIcon {
      transform: rotate(45deg);
    }
    .checkIcon {
      transform: rotate(-45deg);
    }
  }

  // final state
  &:not(.loading)[data-state='checked'] .checkIcon,
  &:not(.loading)[data-state='unchecked'] .closeIcon {
    opacity: 1;
    transform: rotate(0);
  }
  &.loading .spinnerIcon {
    opacity: 1;
  }
}
