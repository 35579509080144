/*
 * TopbarSkeleton - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/Farol-DS---%5BWeb%5D-Core-Components?node-id=2427%3A32083&t=F0jPrZtuBGRYhKCc-0
*/

.bg {
  animation: skeletonBg 1s var(--fds-ease-expressive) infinite alternate;
}

.color {
  animation: skeletonColor 1s var(--fds-ease-expressive) infinite alternate;
}

.radius {
  &circle {
    border-radius: var(--fds-border-radius-circle);
  }

  &text {
    border-radius: var(--fds-border-radius-md);
  }
}

@keyframes skeletonBg {
  0% {
    background-color: var(--fds-color-base-high-2);
  }

  100% {
    background-color: var(--fds-color-base-high-1);
  }
}

@keyframes skeletonColor {
  0% {
    color: var(--fds-color-base-high-2);
  }

  100% {
    color: var(--fds-color-base-high-1);
  }
}
