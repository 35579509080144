/*
 * BannerDescription - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2628%3A128141
*/

.root {
  color: var(--fds-color-base-low-2);
  font-size: var(--fds-font-size-2xs);
  line-height: var(--fds-line-height-md);
  margin-top: var(--fds-spacing-6xs);
}
