/*
 * AccordionContent - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=7092-210877&mode=design&t=kwY4Ref3EGUQ1TGr-11
*/

.root {
  overflow: hidden;

  &[data-state='open'] {
    animation: slideDown var(--fds-duration-medium-1) var(--fds-ease-expressive);
  }

  &[data-state='closed'] {
    animation: slideUp var(--fds-duration-medium-1) var(--fds-ease-expressive);
  }
}

.body {
  padding-top: 0;
  padding-bottom: var(--fds-spacing-3xs);

  &.size {
    &lg,
    &xl {
      padding-top: 0;
    }

    &lg {
      padding-bottom: var(--fds-spacing-2xs);
    }

    &xl {
      padding-bottom: var(--fds-spacing-xs);
    }
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}
