@import '@farol-ds/tokens/index.scss';

.body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  margin-top: var(--fds-spacing-2xs);
  margin-bottom: var(--fds-spacing-sm);
}

.title {
  text-align: center;
  margin-bottom: var(--fds-spacing-3xs);
}

.text {
  text-align: center;
  margin-bottom: var(--fds-spacing-2xs);
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--fds-spacing-2xs);
}

@media screen and (min-width: $breakpoint-sm) {
  .modalFooter {
    div {
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
