/*
 * ToastViewport - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3530-151650&amp;t=b5H5jAfym489e1iL-0
*/
@use './variables.scss' as vars;

// synced with `timeout` prop of <CSSTransition /> in toast-store
$animation-duration: var(--fds-duration-medium-1);

.root {
  display: flex;
  flex-direction: column;
  outline: none;
  margin: 0;
  padding: 0;
  gap: var(--fds-spacing-4xs);
  list-style: none;
  border-radius: var(--fds-border-radius-sm);

  pointer-events: auto;

  & > :first-child {
    margin-top: var(--fds-spacing-4xs);
  }

  & > :last-child {
    margin-bottom: var(--fds-spacing-4xs);
  }

  &:focus {
    outline: none;
  }
}

.container {
  position: fixed;
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
  z-index: vars.$z-index-toast-container;

  pointer-events: none;

  &.top {
    top: 0;
    left: 0;
    right: var(--removed-body-scroll-bar-size, 0);
    flex-direction: column-reverse;
  }

  &.top-start {
    top: 0;
    left: 0;
    flex-direction: column-reverse;
  }

  &.top-end {
    top: 0;
    right: var(--removed-body-scroll-bar-size, 0);
    flex-direction: column-reverse;
  }

  &.bottom {
    left: 0;
    right: var(--removed-body-scroll-bar-size, 0);
    bottom: 0;
  }

  &.bottom-start {
    bottom: 0;
    left: 0;
  }

  &.bottom-end {
    bottom: 0;
    right: var(--removed-body-scroll-bar-size, 0);
  }
}

/* animations */
.container {
  [data-fds-toast] {
    animation-duration: $animation-duration;
    animation-timing-function: var(--fds-ease-expressive);
    animation-iteration-count: 1;
  }

  &.top,
  &.top-start,
  &.top-end {
    [data-fds-toast][data-state='open'] {
      animation-name: slide-down;
      animation-fill-mode: backwards;
    }

    [data-fds-toast][data-state='closed']:not([data-swipe]) {
      animation-name: slide-down-exit;
      animation-fill-mode: forwards;
    }
  }

  &.bottom,
  &.bottom-start,
  &.bottom-end {
    [data-fds-toast][data-state='open'] {
      animation-name: slide-up;
      animation-fill-mode: backwards;
    }

    [data-fds-toast][data-state='closed']:not([data-swipe]) {
      animation-name: slide-up-exit;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(calc(100% + #{var(--fds-spacing-6xs)})) var(--fds-scale-xs);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes slide-up-exit {
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  to {
    opacity: 0;
    transform: translateY(calc(100% + #{var(--fds-spacing-6xs)})) var(--fds-scale-xs);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(calc(calc(100% - #{var(--fds-spacing-6xs)}) * -1)) var(--fds-scale-xs);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes slide-down-exit {
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  to {
    opacity: 0;
    transform: translateY(calc(calc(100% - #{var(--fds-spacing-6xs)}) * -1)) var(--fds-scale-xs);
  }
}
