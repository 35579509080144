/*
 * SelectionCardBodyContent - Farol DS
 * https://www.figma.com/design/QmFJQRbQfkP6Upr9TNreoO/branch/JxPEDKGTkBL604V7Rmgx5V/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?node-id=8446-8102&amp;node-type=canvas&amp;t=LTNIOKGEZSjMJbD0-0
*/

.root {
  display: flex;
  flex-direction: column;
  gap: var(--fds-spacing-6xs);
  flex: 1;
}
