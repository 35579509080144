/* WE CAN'T USE FAROL/MYSTIQUE VARIABLES HERE */

.root {
  user-select: none;
  pointer-events: none;
  opacity: 0;
  border: none;
  width: 0;
  height: 0;
  position: fixed;

  &[data-opened='true'] {
    user-select: auto;
    pointer-events: auto;
    opacity: 1;
  }
}

.modal {
  background-color: transparent;
  bottom: -1000px;
  left: -1000px;

  &[data-opened='true'] {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
}

.inline {
  &[data-opened='true'] {
    position: initial;
    width: initial;
    height: initial;
  }
}
