/*
 * Caption - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=911%3A66480
*/

.root {
  color: var(--fds-color-base-low-3);
  font-weight: var(--fds-font-weight-regular);
  font-size: var(--fds-font-size-3xs);
  line-height: var(--fds-line-height-sm);
}
