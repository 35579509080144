/*
 * Spinner - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=431%3A10226
*/

.root {
  /* animation */
  animation-name: spinner;
  animation-duration: var(--fds-duration-slow-2);
  animation-iteration-count: infinite;
  animation-timing-function: var(--fds-ease-loop);
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: var(--fds-rotate-xl);
  }
}
