/*
 * Quantity - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3328%3A120781&amp;t=hRFeMhukzbXIKgNs-0
*/

.root {
  display: inline-flex;
  flex-direction: row;
  gap: var(--fds-spacing-5xs);
}

.input {
  text-align: center;
}
