/*
 * RadioName - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1219%3A76673
*/

.root {
  color: var(--fds-color-base-low-pure);
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-regular);
  line-height: var(--fds-line-height-md);

  transition-property: color;
  transition-duration: var(--fds-duration-fast-1);
  transition-timing-function: var(--fds-ease-productive);
}
