/*
 * SnippetBody - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=4535-11220&mode=design&t=ETTkviXdl3WWicIo-11
*/

.root {
  display: flex;
  flex-direction: column;
  gap: var(--fds-spacing-4xs);
}
