@use './variables';
@use '../css/mixins.scss' as m;
@import '@farol-ds/tokens/index.scss';

/*
 * Search - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3225%3A107400&amp;t=2UpKsobYS1bqmHSS-0
*/

.container {
  --fds-search-icons-count: 1;

  &.clearable {
    --fds-search-icons-count: 2;
  }
}

$icons-size: calc(#{variables.$icon-size} * var(--fds-search-icons-count));
$icons-between-area: var(--fds-spacing-5xs);
$icons-left-area: var(--fds-spacing-4xs);
$input-right-area: calc(#{$icons-size} + #{$icons-left-area} + #{$icons-between-area});

@mixin input-position($y, $x) {
  .input {
    padding: $y $x;
    margin: calc(-1 * $y) calc(-1 * $x);
  }
}

.container {
  overflow: hidden;
  display: flex;
  position: relative;
  transition-property: border-color, shadow;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  border-width: var(--fds-border-width-sm);
  border-style: solid;
  height: 44px;
  min-height: 44px;
  flex: 1;
  font-size: var(--fds-font-size-xs);
  color: var(--fds-color-base-low-pure);
  padding-block: var(--fds-spacing-6xs);
  padding-left: var(--fds-spacing-3xs);
  padding-right: calc(#{$input-right-area} + #{var(--fds-spacing-4xs)});

  @include input-position(var(--fds-spacing-6xs), var(--fds-spacing-3xs));

  &:hover {
    border-color: var(--fds-color-base-high-4);
  }

  &Focus {
    @include m.focus-style;
  }
}

.filter {
  display: none;
}

.input {
  all: unset;
  flex: 1;

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: var(--fds-color-base-low-3);
    opacity: 1;
  }

  /* remove magnify/cancel icon from native browsers */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button {
    appearance: none;
  }
}

.type {
  &primary {
    background-color: var(--fds-color-base-high-1);
    border-color: var(--fds-color-base-high-1);
    border-radius: var(--fds-border-radius-pill);
  }

  &secondary {
    background-color: var(--fds-color-base-high-pure);
    border-color: var(--fds-color-base-high-4);
    height: 40px;
    min-height: 40px;
    border-radius: var(--fds-border-radius-pill);

    &:hover {
      border-color: var(--fds-color-base-low-4);
    }
  }
}

.size {
  &lg {
    font-size: var(--fds-font-size-xs);
    padding-block: var(--fds-spacing-5xs);
    @include input-position(var(--fds-spacing-5xs), var(--fds-spacing-3xs));

    &.type {
      &primary {
        height: 64px;
        min-height: 64px;

        padding: var(--fds-spacing-4xs) var(--fds-spacing-2xs);
        padding-right: calc(#{$input-right-area} + #{var(--fds-spacing-2xs)});

        @include input-position(var(--fds-spacing-4xs), var(--fds-spacing-2xs));

        & > .icons {
          margin-right: var(--fds-spacing-2xs);
        }
      }

      &secondary {
        height: 56px;
        min-height: 56px;

        padding: var(--fds-spacing-4xs) var(--fds-spacing-3xs);
        padding-right: calc(#{$input-right-area} + #{var(--fds-spacing-3xs)});

        @include input-position(var(--fds-spacing-4xs), var(--fds-spacing-3xs));

        & > .icons {
          margin-right: var(--fds-spacing-3xs);
        }
      }
    }
  }
}

.icons {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 var(--fds-spacing-4xs);

  & *:not(:last-child) {
    margin-right: $icons-between-area;
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--fds-color-base-low-3);
  color: var(--fds-color-base-low-3);
  width: variables.$icon-size;
  height: variables.$icon-size;
  min-width: variables.$icon-size;
  min-height: variables.$icon-size;
}

.disabled {
  cursor: default;
  pointer-events: none;
  border-color: var(--fds-color-base-high-1);

  .input {
    &,
    &::placeholder {
      color: var(--fds-color-base-high-4);
    }

    + .icons .spinner {
      color: var(--fds-color-base-high-4);
      color: var(--fds-color-base-high-4);
    }
  }
}

.formControl {
  padding-left: var(--fds-spacing-3xs);

  &Lg {
    padding-left: var(--fds-spacing-2xs);
  }
}

@media (min-width: $breakpoint-md) {
  .container:not(.sizelg) {
    font-size: var(--fds-font-size-2xs);
  }
  .filter {
    display: flex;
    align-items: center;
  }
}
