@import '@farol-ds/tokens/index.scss';

/*
 * Row - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2104%3A99197
*/

.root {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

@media (min-width: $breakpoint-sm) {
  .root {
    margin-left: -16px;
    margin-right: -16px;
  }
}
