/*
 * DrawerBody - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2348%3A117913
*/

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--fds-spacing-3xs);
  overflow-x: hidden;
  overflow-y: auto;
}
