/*
 * SwitchLabel - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&amp;node-id=7279-209249&amp;mode=design&amp;t=gjHB6MlU1QyBFgq5-0
*/

.root {
  color: var(--fds-color-base-low-1);
  font-size: var(--fds-font-size-2xs);
  line-height: var(--fds-line-height-md);

  display: flex;
  align-items: center;

  transition-property: color;
  transition-duration: var(--fds-duration-fast-1);
  transition-timing-function: var(--fds-ease-productive);

  &.sm {
    padding-left: var(--fds-spacing-6xs);
    font-weight: var(--fds-font-weight-regular);
  }
  &.md {
    padding-left: var(--fds-spacing-5xs);
    font-weight: var(--fds-font-weight-semibold);
  }

  /* hover and disabled were defined in the switch.module.scss */
}
