/*
 * BreadcrumbItem - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2204%3A103104
*/

.root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--fds-color-base-low-2);
  font-size: var(--fds-font-size-2xs);

  & > * {
    display: inline-flex;
  }
}

.arrowIcon {
  color: var(--fds-color-base-low-4);
  margin: 0 var(--fds-spacing-5xs);
}
