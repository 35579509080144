@use '../variables';
@import '@farol-ds/tokens/index.scss';

/*
 * TopbarContainer - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/Farol-DS---%5BWeb%5D-Core-Components?node-id=2427%3A32083&t=F0jPrZtuBGRYhKCc-0
*/

.root {
  background-color: var(--fds-color-base-high-pure);
  border-bottom: var(--fds-border-width-sm) solid var(--fds-color-base-high-2);
  z-index: variables.$topbar-z-index;
  position: relative;
}

.takeover {
  background-color: var(--fds-color-feedback-negative-2);
}

.position {
  &fixed,
  &fixed-mobile-only {
    /* important values to be synced with breakpoints below */
    height: variables.$topbar-default-sm;

    &.variantslim {
      height: variables.$topbar-slim-sm;
    }

    .root {
      position: fixed;
      width: 100%;
      z-index: 1000;
    }
  }
}

@media (min-width: $breakpoint-sm) {
  .position {
    &fixed-mobile-only {
      .root {
        position: relative;
      }
    }
  }
}

@media (min-width: $breakpoint-md) {
  .position {
    &fixed,
    &fixed-mobile-only {
      height: variables.$topbar-default-md;

      &.variantslim {
        height: variables.$topbar-slim-md;
      }
    }

    &fixed-mobile-only {
      .root {
        z-index: variables.$topbar-z-index;
      }
    }
  }
}
