/*
 * CheckboxItem - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3213%3A103092&amp;t=uiCRBFHLDxTn4uPF-0
*/

.inline {
  display: inline;
  vertical-align: middle;
}

.root {
  display: inline-flex;

  &:not(.disabled):hover {
    .itemContent {
      cursor: pointer;
    }

    .itemName {
      color: var(--fds-color-base-low-1);
    }
    .itemDescription {
      color: var(--fds-color-base-low-2);
    }
  }

  &.disabled {
    .itemName,
    .itemDescription {
      color: var(--fds-color-base-high-4);
    }
  }
}
