/*
 * Overlay - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1177%3A72180
*/

@use './variables.scss' as vars;

$opacity: var(--fds-opacity-level-2);

.root {
  background-color: var(--fds-c-overlay-background);
  pointer-events: none;
  position: fixed;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: vars.$z-index-overlay;

  &.visible {
    opacity: $opacity;
    pointer-events: auto;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .root {
    /* animation */
    transition-property: opacity, visibility;
    transition-duration: var(--fds-duration-medium-2);
    transition-timing-function: var(--fds-ease-expressive);
  }

  .root.enter {
    animation: fadeIn var(--fds-duration-medium-2) var(--fds-ease-expressive);
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: $opacity;
    }
  }
}
