/*
 * AutocompleteItem - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3349-128843&amp;t=KcYW6wA6FA8I0CDR-0
*/

.root {
  transition-property: background-color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  background-color: var(--fds-color-base-high-pure);
  border-radius: var(--fds-border-radius-sm);
  list-style: none;
  margin: 0;
  display: flex;
  align-items: stretch;
  cursor: pointer;
  text-align: left;

  text-decoration: none;
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-regular);
  line-height: var(--fds-line-height-sm);

  color: var(--fds-color-base-low-1);

  &:hover {
    background-color: var(--fds-color-base-high-1);
  }

  &:active {
    background-color: var(--fds-color-base-high-2);
  }

  // props
  &.history {
    color: var(--fds-extra-color-visited);
  }

  &[aria-selected],
  &.active {
    background-color: var(--fds-color-base-high-1);
  }
}
