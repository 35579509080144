@import '@farol-ds/tokens/index.scss';

/*
 * CarouselList - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&node-id=3688-153678&mode=design&t=6oqxROj7v1PG7rDq-0
*/

.root {
  display: flex;
  position: relative;
  overflow: auto;
  scroll-snap-type: x mandatory;
  list-style-type: none;
  margin: 0;
  padding: 0;

  // hide scrollbar
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
