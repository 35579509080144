.content {
  display: flex;
  gap: var(--fds-spacing-6xs);
  color: var(--fds-color-base-low-2) !important;

  &__age {
    white-space: nowrap;
  }

  &__states {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
}
