/*
 * RadioDescription - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1219%3A76673
*/

.root {
  color: var(--fds-color-base-low-1);

  transition-property: color;
  transition-duration: var(--fds-duration-fast-1);
  transition-timing-function: var(--fds-ease-productive);
}
