/*
 * DetailLabel - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2708%3A128141
*/

.root {
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-semibold);

  &:not(a) {
    color: var(--fds-color-base-low-pure);
  }
}
