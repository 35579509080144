@import '@farol-ds/tokens/index.scss';

/*
 * Card - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=856%3A46019
*/

/* base */
.root {
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-3);
  border-radius: var(--fds-border-radius-md);
  overflow: hidden;
  width: 100%;
  display: block;

  &:not(.disablePadding) {
    padding: var(--fds-spacing-2xs);
  }
}

/* clickable */
a.root,
button.root,
.clickable {
  cursor: pointer;
  text-decoration: none;
  text-align: inherit;

  /* animation */
  transition-property: box-shadow, border-color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  /* states */
  &.root:hover,
  &.root:active {
    border-color: var(--fds-color-base-high-4);
  }
  &.highlight:hover,
  &.highlight:active {
    border-color: var(--fds-color-brand-primary-3);
  }

  &:hover {
    box-shadow: var(--fds-shadow-level-3);
  }
  &:active {
    box-shadow: var(--fds-shadow-level-1);
  }

  &.disabled {
    border-color: var(--fds-color-base-high-1);
    pointer-events: none;
  }
}

.highlight {
  border: var(--fds-border-width-md) solid var(--fds-color-brand-primary-pure);
}

@media (min-width: $breakpoint-sm) {
  .root:not(.disablePadding) {
    padding: var(--fds-spacing-2xs);
  }
}
