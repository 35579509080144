@use 'sass:map';
@use './variables';
@use '../icon/icon.module.scss' as icon;

/*
 * Banner - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2628%3A128141
*/

.root {
  width: 100%;
  border: var(--fds-border-width-sm) solid transparent;
  padding: variables.$padding;
  border-radius: var(--fds-border-radius-sm);
  display: flex;
  flex-direction: column;
  position: relative;

  &.hasIconClose [data-fds-banner-title] {
    padding-right: variables.$close-icon-offset;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .root {
    transition-duration: var(--fds-duration-medium-2);
    transition-timing-function: var(--fds-ease-expressive);
    transition-property: opacity;
    opacity: 1;

    &.isHidding {
      opacity: 0;
    }
  }
}

.level {
  &app {
    border-radius: unset;
    border-left: unset;
    border-right: unset;
    justify-content: center;
  }
}

.type {
  &info {
    background-color: var(--fds-color-feedback-info-1);
    border-color: var(--fds-color-feedback-info-2);

    [data-fds-banner-icon] * {
      color: var(--fds-color-feedback-info-pure);
    }
  }

  &positive {
    background-color: var(--fds-color-feedback-positive-1);
    border-color: var(--fds-color-feedback-positive-2);

    [data-fds-banner-icon] * {
      color: var(--fds-color-feedback-positive-3);
    }
  }

  &warning {
    background-color: var(--fds-color-feedback-warning-1);
    border-color: var(--fds-color-feedback-warning-2);

    [data-fds-banner-icon] * {
      color: var(--fds-color-feedback-warning-4);
    }
  }

  &critical {
    background-color: var(--fds-color-feedback-negative-1);
    border-color: var(--fds-color-feedback-negative-2);

    [data-fds-banner-icon] * {
      color: var(--fds-color-feedback-negative-pure);
    }
  }

  &discovery {
    background-color: var(--fds-color-base-high-1);
    border-color: var(--fds-color-base-high-3);

    [data-fds-banner-icon] * {
      color: var(--fds-color-base-low-pure);
    }
  }
}

@media (min-width: variables.$breakpoint-inline) {
  .root {
    flex-direction: row;
    align-items: center;

    &:not(.hasIconClose) [data-fds-banner-action] {
      margin-right: 0;
    }

    &.hasIconClose [data-fds-banner-title] {
      padding-right: 0;
    }
  }
}
