/*
 * FormControlLabel - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1281%3A92990
*/

.root {
  color: var(--fds-color-base-low-pure);
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-semibold);
  line-height: var(--fds-line-height-md);
  margin-bottom: var(--fds-spacing-6xs);
}

.required {
  color: var(--fds-color-feedback-negative-pure);
  font-size: var(--fds-font-size-2xs);
  line-height: var(--fds-line-height-md);
  margin-left: var(--fds-spacing-6xs);
}
