/*
 * SelectionCard - Farol DS
 * https://www.figma.com/design/QmFJQRbQfkP6Upr9TNreoO/branch/JxPEDKGTkBL604V7Rmgx5V/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?node-id=8446-8102&amp;node-type=canvas&amp;t=LTNIOKGEZSjMJbD0-0
*/

.root {
  display: flex;
  gap: var(--fds-spacing-3xs);
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-3);
  border-radius: var(--fds-border-radius-sm);
  padding: var(--fds-spacing-3xs);
  overflow: hidden;
  text-align: left;
  cursor: pointer;
  line-height: initial;
  width: 100%;

  transition-property: border, box-shadow;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  &:hover {
    border-color: var(--fds-color-base-low-3);
  }
}

.checked {
  border-color: var(--fds-color-brand-primary-pure);
  box-shadow: 0 0 0 var(--fds-border-width-sm) var(--fds-color-brand-primary-pure);

  &:hover {
    border-color: var(--fds-color-brand-primary-3);
    box-shadow: 0 0 0 var(--fds-border-width-sm) var(--fds-color-brand-primary-3);
  }
}

.disabled {
  border-color: var(--fds-color-base-high-1);
  pointer-events: none;

  &.checked {
    box-shadow: 0 0 0 var(--fds-border-width-sm) var(--fds-color-base-high-1);
  }
}
