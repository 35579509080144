.options {
  display: flex;
  gap: var(--fds-spacing-xs);
  margin-top: var(--fds-spacing-5xs);
}

.optionSub {
  color: var(--fds-color-base-low-3);
  margin-block: var(--fds-spacing-3xs);
  text-align: center;
  display: block;
}
