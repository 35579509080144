/*
 * Brand - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2235%3A121053
*/

.root {
  display: inline-flex;
  align-self: center;
  justify-self: center;
  vertical-align: middle;
  overflow: hidden;
}

.positive {
  color: var(--fds-c-brand-text-color-positive);
}
.negative {
  color: var(--fds-c-brand-text-color-negative);
}
