@import '@farol-ds/tokens/index.scss';

.list {
  display: flex;
  flex-direction: column;
  gap: var(--fds-spacing-3xs);
}

.error {
  margin-bottom: var(--fds-spacing-3xs);
}

@media (min-width: $breakpoint-sm) {
  .list {
    height: 567px;
  }
}
