/*
 * Tooltip - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=30%3A13855
*/

$z-index-tooltip: 9010;

/* 1. base */
.root {
  background-color: var(--fds-color-base-low-pure);
  border-radius: var(--fds-border-radius-sm);
  max-width: 254px;
  padding: var(--fds-spacing-5xs);
  z-index: $z-index-tooltip;

  &:not(:focus) {
    box-shadow: var(--fds-shadow-level-5);
  }
}

/* 2. text */
.label {
  margin: 0;
  color: var(--fds-color-base-high-pure);
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-regular);
  line-height: var(--fds-line-height-sm);
}

/* 3. animation */
/* [FIXME] revisit it to reuse code between components or not. @andersonba */
.root {
  animation-duration: var(--fds-duration-fast-2);
  animation-timing-function: var(--fds-ease-productive);

  &[data-side='top'] {
    animation-name: slide-up;
  }

  &[data-side='bottom'] {
    animation-name: slide-down;
  }

  &[data-side='left'] {
    animation-name: slide-left;
  }

  &[data-side='right'] {
    animation-name: slide-right;
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(var(--fds-spacing-3xs)) var(--fds-scale-xs);
  }

  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(calc(#{var(--fds-spacing-3xs)} * -1)) var(--fds-scale-xs);
  }

  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(var(--fds-spacing-6xs)) var(--fds-scale-xs);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(calc(#{var(--fds-spacing-6xs)} * -1)) var(--fds-scale-xs);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
