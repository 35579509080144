/*
 * FileLabel - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2486%3A122501
*/

.root {
  color: var(--fds-color-base-low-pure);
  font-size: var(--fds-font-size-2xs);
  line-height: var(--fds-line-height-sm);
  font-weight: var(--fds-font-weight-semibold);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.selected {
  color: var(--fds-color-base-high-pure);
}
