/*
 * MenuItemContent - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1229%3A106110
*/

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: inherit;
  fill: inherit;
}

.check {
  margin-left: auto;
  color: inherit;
  fill: inherit;

  &Icon {
    margin-left: var(--fds-spacing-4xs);
  }
}

.root > * {
  display: inline-flex;
}
