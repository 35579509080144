/*
 * BannerTitle - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2628%3A128141
*/

.root {
  color: var(--fds-color-base-low-pure);
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-semibold);
  line-height: var(--fds-line-height-md);
  white-space: pre-wrap;
  word-break: break-word;

  a {
    &,
    &:hover {
      color: var(--fds-color-base-low-pure);
    }
    &:hover {
      opacity: var(--fds-opacity-level-1);
    }
  }
}

.level {
  &app {
    text-align: center;
  }
}
