@import '@farol-ds/tokens/index.scss';
/*
 * TruncateText - Farol DS
*/

$transition-delay: 1s;

$breakpoints: (
  0: $breakpoint-xs,
  1: $breakpoint-sm,
  2: $breakpoint-md,
  3: $breakpoint-lg,
  4: $breakpoint-xl,
);

.root {
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(.single) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  &.single {
    white-space: nowrap;
  }

  &.fade {
    position: relative;

    &::after {
      transition: height, opacity;
      transition-duration: var(--fds-duration-medium-2);
      transition-timing-function: var(--fds-ease-expressive);
      transition-delay: $transition-delay;

      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0;
      background: linear-gradient(180deg, #ffffff00, #{var(--fds-color-base-high-pure)} 100%);
      pointer-events: none;
      opacity: 0;
    }

    &md::after {
      height: 48px;
      opacity: 1;
    }

    &.fadeRehydrated::after {
      transition-delay: unset;
    }
  }

  @each $idx, $b in $breakpoints {
    @if $idx == 0 {
      --tr-#{$idx}: var(--t-0);
    } @else {
      --tr-#{$idx}: var(--t-#{$idx}, var(--tr-#{($idx - 1)}));
    }
  }
}

@each $idx, $breakpoint in $breakpoints {
  @media (min-width: $breakpoint) {
    .root {
      line-clamp: var(--tr-#{$idx});
      -webkit-line-clamp: var(--tr-#{$idx});
    }
  }
}
