/*
 * RadioItem - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1219%3A76673
*/

.root {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &:not(.disabled):hover {
    .itemContent {
      cursor: pointer;
    }

    .itemName {
      color: var(--fds-color-base-low-1);
    }
    .itemDescription {
      color: var(--fds-color-base-low-2);
    }
  }

  &.disabled {
    .itemName,
    .itemDescription {
      color: var(--fds-color-base-high-4);
    }
  }
}
