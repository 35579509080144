@use 'sass:map';
@use '../icon/variables.scss' as icon;

/*
 * IconListItem - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3292%3A124577&amp;t=RaQYEippPBkPM6dD-0
*/

.root {
  display: flex;
  align-items: flex-start;
}

.icon {
  display: flex;
  align-items: center;
}

.text {
  min-height: map.get(icon.$sizes, 'md');
  display: flex;
  align-items: center;
}
