/*
 * Chip - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2304%3A111592
*/

.root {
  display: inline-flex;
  align-items: center;
  font-weight: var(--fds-font-weight-regular);
  text-decoration: none;
  vertical-align: middle;

  border: var(--fds-border-width-sm) solid transparent;
  border-radius: var(--fds-border-radius-pill);
  transition-property: background-color, border-color, color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
}

.leftIcon {
  display: inline-flex;
  margin-right: var(--fds-spacing-6xs);
  color: inherit;
  vertical-align: middle;
}

.kind {
  &info {
    background-color: var(--fds-color-feedback-info-1);
    border-color: var(--fds-color-feedback-info-1);
    color: var(--fds-color-feedback-info-3);
  }

  &negative {
    background-color: var(--fds-color-feedback-negative-1);
    border-color: var(--fds-color-feedback-negative-1);
    color: var(--fds-color-feedback-negative-3);
  }

  &warning {
    background-color: var(--fds-color-feedback-warning-1);
    border-color: var(--fds-color-feedback-warning-1);
    color: var(--fds-color-feedback-warning-4);
  }

  &positive {
    background-color: var(--fds-color-feedback-positive-1);
    border-color: var(--fds-color-feedback-positive-1);
    color: var(--fds-color-feedback-positive-4);
  }

  &secondary {
    background-color: var(--fds-color-brand-primary-1);
    border-color: var(--fds-color-brand-primary-1);
    color: var(--fds-color-brand-primary-pure);
  }

  &tertiary {
    background-color: var(--fds-color-base-high-1);
    border-color: var(--fds-color-base-high-1);
    color: var(--fds-color-base-low-1);
  }
}

.size {
  &sm {
    min-height: 24px;
    height: 24px;
    padding: var(--fds-spacing-6xs) var(--fds-spacing-5xs);
    font-size: var(--fds-font-size-3xs);
    line-height: var(--fds-line-height-xs);
  }

  &md {
    min-height: 32px;
    height: 32px;
    padding: var(--fds-spacing-6xs) var(--fds-spacing-4xs);
    font-size: var(--fds-font-size-2xs);
    line-height: var(--fds-line-height-sm);
  }
}
