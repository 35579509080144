@import '@farol-ds/tokens/index.scss';

/*
 * DropdownMenu - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1165%3A66892
*/

/*
 * This component use responsive global styles. Check it out:
 * ../css/components/_dropdown.scss
 */

$z-index-dropdown-menu: 9000;

.root {
  --dropdown-menu-animation-duration: #{var(--fds-duration-fast-2)};
  --dropdown-menu-animation-distance: #{var(--fds-spacing-5xs)};
  --dropdown-menu-animation-delay: 0;
  --dropdown-menu-animation-post-delay: 0;

  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-3);
  border-radius: var(--fds-border-radius-md);
  box-shadow: var(--fds-shadow-level-2);
  z-index: $z-index-dropdown-menu;
}

.container {
  display: flex;
  flex-direction: column;
  padding: var(--fds-spacing-5xs);
  overflow-x: hidden;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  min-height: 64px;
  height: 64px;
  padding: 0 var(--fds-spacing-3xs);
  justify-content: flex-end;

  &Title {
    flex: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .root {
    animation-duration: var(--dropdown-menu-animation-duration);
    animation-timing-function: var(--fds-ease-productive);
    animation-delay: var(--dropdown-menu-animation-delay);
    animation-fill-mode: forwards;
    will-change: transform, opacity;

    &[data-state='open'][data-side='top'] {
      animation-name: slide-up;
    }

    &[data-state='open'][data-side='right'] {
      animation-name: slide-right;
    }

    &[data-state='open'][data-side='bottom'] {
      animation-name: slide-down;
    }

    &[data-state='open'][data-side='left'] {
      animation-name: slide-left;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .root {
    --dropdown-menu-animation-distance: #{var(--fds-spacing-6xl)};
    --dropdown-menu-animation-delay: 160ms;
    --dropdown-menu-animation-post-delay: 120ms;
  }

  .root,
  .container {
    width: 100%;
  }

  .root {
    border-width: 0;
    border-top-left-radius: var(--fds-border-radius-md);
    border-top-right-radius: var(--fds-border-radius-md);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    opacity: 0;

    /* div blocker - prevent miss-click */
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      animation-name: hide;
      animation-delay: calc(
        var(--dropdown-menu-animation-delay) + var(--dropdown-menu-animation-duration) +
          var(--dropdown-menu-animation-post-delay)
      );
      animation-fill-mode: forwards;
      will-change: visibility;
    }

    &[data-state='open'][data-side='top'] {
      animation-name: slide-up;
    }

    &[data-state='open'][data-side='right'] {
      animation-name: slide-up;
    }

    &[data-state='open'][data-side='bottom'] {
      animation-name: slide-up;
    }

    &[data-state='open'][data-side='left'] {
      animation-name: slide-up;
    }
  }

  .container {
    padding: var(--fds-spacing-3xs) var(--fds-spacing-3xs);
    padding-bottom: var(--fds-spacing-md);
    max-height: calc(95vh - 64px);
    /* minus header */
  }
}

@media (min-width: $breakpoint-sm) {
  .header,
  .headerSeparator {
    display: none;
  }
}

@keyframes hide {
  0% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(var(--dropdown-menu-animation-distance));
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(calc(var(--dropdown-menu-animation-distance) * -1));
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(var(--dropdown-menu-animation-distance));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(calc(var(--dropdown-menu-animation-distance) * -1));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
