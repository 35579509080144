@use './mixins.scss' as m;

html:not([data-fds-ready]) * {
  transition: none !important;
}

body {
  overflow-y: scroll;
}

html {
  font-family: var(--fds-font-family-base);
  font-weight: var(--fds-font-weight-regular);

  // antialiasing
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong,
b {
  font-weight: var(--fds-font-weight-bold);
}

button {
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

blockquote {
  border-left: 2px solid var(--fds-color-base-high-3);
  padding-left: var(--fds-spacing-4xs);
}

cite {
  margin-top: var(--fds-spacing-5xs);
  font-style: italic;
  display: block;

  &::before {
    content: '— ';
    display: inline;
  }
}

body {
  background-color: var(--fds-color-base-high-pure);
  color: var(--fds-color-base-low-2);

  :focus-visible {
    @include m.focus-style;
  }
  @supports not selector(:focus-visible) {
    :focus {
      @include m.focus-style;
    }
  }
}

/* SVG reset */
svg,
svg * {
  color: inherit;
}

/* links */
a {
  transition-property: color, text-decoration-color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);
  color: var(--fds-color-feedback-info-pure);
  border-radius: var(--fds-border-radius-xs);
  text-decoration: underline;

  &:hover {
    text-decoration-color: transparent;
    color: var(--fds-color-feedback-info-3);
  }

  &:visited,
  &:hover:visited {
    color: var(--fds-extra-color-visited);
  }
}

/* experimental flags */
