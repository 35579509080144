/* Constants */
$topbar-header-sm-height: 64px;
$topbar-header-md-height: 64px;
$topbar-nav-height: 56px;
$topbar-border-size: var(--fds-border-width-sm);
$topbar-z-index: 2;

/* Calculations */
// default
$topbar-default-sm: calc(
  #{$topbar-header-sm-height} + #{$topbar-nav-height} + #{$topbar-border-size}
);
$topbar-default-md: calc(
  #{$topbar-header-md-height} + #{$topbar-nav-height} + #{$topbar-border-size}
);
// slim
$topbar-slim-sm: calc(#{$topbar-header-sm-height} + #{$topbar-border-size});
$topbar-slim-md: calc(#{$topbar-header-md-height} + #{$topbar-border-size});
