/*
 * MenuItemTrailing - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=1229%3A106110
*/

.root {
  display: flex;
  align-items: center;
  fill: inherit;
  color: inherit;
  margin-left: auto;

  & + [data-fds-menu-item-content-check] {
    margin-left: 0;
  }

  & .icon,
  & + [data-fds-menu-item-content-check] {
    fill: inherit;
    color: inherit;
  }
}

.icon {
  margin-left: var(--fds-spacing-4xs);
}
