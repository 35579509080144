/*
 * Button - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=648%3A13728
*/

@mixin icons-spacing($spacing) {
  .leftIcon {
    margin-right: $spacing;
  }
  .rightIcon {
    margin-left: $spacing;
  }
}

.leftIcon,
.rightIcon {
  display: flex;
}

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: var(--fds-border-width-sm);
  border-style: solid;
  border-radius: var(--fds-border-radius-sm);
  text-decoration: none;

  /* text */
  font-weight: var(--fds-font-weight-semibold);
  line-height: var(--fds-line-height-xs);

  /* animation */
  transition-property: background, border, color;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  /* cursor */
  cursor: pointer;

  /* shadow */
  &:not(:focus) {
    box-shadow: var(--fds-shadow-level-1);
  }

  &:active {
    box-shadow: none;
  }

  /* icons */
  .leftIcon,
  .rightIcon {
    color: inherit;
  }
}

.size {
  &sm {
    height: 32px;
    min-width: 40px;
    border-radius: var(--fds-border-radius-xs);
    padding: var(--fds-spacing-5xs);
    font-size: var(--fds-font-size-3xs);

    @include icons-spacing(var(--fds-spacing-6xs));
  }

  &md {
    height: 44px;
    min-width: 56px;
    padding: var(--fds-spacing-5xs) var(--fds-spacing-4xs);
    font-size: var(--fds-font-size-2xs);

    @include icons-spacing(var(--fds-spacing-5xs));
  }

  &lg {
    height: 56px;
    min-width: 72px;
    padding: var(--fds-spacing-3xs);
    font-size: var(--fds-font-size-xs);

    @include icons-spacing(var(--fds-spacing-4xs));
  }
}

.kind {
  &primary {
    border-color: var(--fds-color-brand-primary-pure);
    background-color: var(--fds-color-brand-primary-pure);

    &,
    &:visited,
    .spinner {
      color: var(--fds-color-base-high-pure);
    }

    &:not(.nointeractive) {
      &:hover {
        background-color: var(--fds-color-brand-primary-3);
        border-color: var(--fds-color-brand-primary-3);
        color: var(--fds-color-base-high-pure);
      }

      &:active {
        background-color: var(--fds-color-brand-primary-4);
        border-color: var(--fds-color-brand-primary-4);
      }
    }
  }

  &secondary {
    border-color: var(--fds-color-base-high-4);
    background-color: var(--fds-color-base-high-pure);

    &,
    &:visited,
    .spinner {
      color: var(--fds-color-brand-primary-pure);
    }

    &:not(.nointeractive) {
      &:hover {
        background-color: var(--fds-color-brand-primary-1);
        border-color: var(--fds-color-brand-primary-2);
        color: var(--fds-color-brand-primary-3);
      }

      &:active {
        background-color: var(--fds-color-brand-primary-2);
        border-color: var(--fds-color-brand-primary-2);
        color: var(--fds-color-brand-primary-4);
      }
    }
  }

  &tertiary {
    border-color: var(--fds-color-base-high-4);
    background-color: var(--fds-color-base-high-pure);

    &,
    &:visited,
    .spinner {
      color: var(--fds-color-base-low-1);
    }

    &:not(.nointeractive) {
      &:hover {
        background-color: var(--fds-color-base-high-1);
        border-color: var(--fds-color-base-high-2);
        color: var(--fds-color-base-low-1);
      }

      &:active {
        background-color: var(--fds-color-base-high-2);
        border-color: var(--fds-color-base-high-2);
      }
    }
  }

  &plain {
    background-color: transparent;
    border: 0;

    &.root,
    &.root:active {
      box-shadow: none;
    }

    &,
    &:visited,
    .spinner {
      color: var(--fds-color-brand-primary-pure);
    }

    &.size {
      &sm,
      &md,
      &lg {
        padding: 0;
        height: auto;
        min-width: auto;
      }

      // overriding spacing for Plain only
      &sm,
      &md {
        @include icons-spacing(var(--fds-spacing-6xs));
      }
      &lg {
        @include icons-spacing(var(--fds-spacing-5xs));
      }
    }

    &:not(.nointeractive) {
      &:hover {
        color: var(--fds-color-brand-primary-3);
      }

      &:active {
        color: var(--fds-color-brand-primary-4);
      }
    }
  }

  &danger {
    background-color: var(--fds-color-feedback-negative-pure);
    border-color: var(--fds-color-feedback-negative-pure);

    &,
    &:visited,
    .spinner {
      color: var(--fds-color-base-high-pure);
    }

    &:not(.nointeractive) {
      &:hover {
        background-color: var(--fds-color-feedback-negative-3);
        border-color: var(--fds-color-feedback-negative-3);
        color: var(--fds-color-base-high-pure);
      }

      &:active {
        background-color: var(--fds-color-feedback-negative-4);
        border-color: var(--fds-color-feedback-negative-4);
      }
    }
  }

  &dangerSecondary {
    border-color: var(--fds-color-base-high-4);
    background-color: var(--fds-color-base-high-pure);

    &,
    &:visited,
    .spinner {
      color: var(--fds-color-feedback-negative-pure);
    }

    &:not(.nointeractive) {
      &:hover {
        background-color: var(--fds-color-feedback-negative-1);
        border-color: var(--fds-color-feedback-negative-2);
        color: var(--fds-color-feedback-negative-3);
      }

      &:active {
        background-color: var(--fds-color-feedback-negative-2);
        border-color: var(--fds-color-feedback-negative-2);
        color: var(--fds-color-feedback-negative-4);
      }
    }
  }
}

.disabled {
  user-select: none;

  &,
  &:hover,
  .spinner {
    color: var(--fds-color-base-high-4);
  }

  &.kind {
    &primary {
      background-color: var(--fds-color-base-high-1);
      border-color: var(--fds-color-base-high-1);
    }

    &secondary {
      border-color: var(--fds-color-base-high-1);
    }

    &tertiary {
      border-color: var(--fds-color-base-high-1);
    }

    &plain {
      &:not(.loading),
      .spinner {
        color: var(--fds-color-base-high-3);
      }
    }

    &danger {
      background-color: var(--fds-color-base-high-1);
      border-color: var(--fds-color-base-high-1);
    }

    &dangerSecondary {
      border-color: var(--fds-color-base-high-1);
    }
  }
}

.block {
  display: flex;
  width: 100%;
}

.loading {
  &,
  &:hover {
    color: transparent;
  }

  & > :not(.spinner) {
    visibility: hidden;
    opacity: 0;
  }

  .spinner {
    position: absolute;
    display: flex;
    align-items: center;
  }
}

.nointeractive {
  cursor: not-allowed;

  &.root {
    box-shadow: none;
  }
}

a.nointeractive {
  pointer-events: none;
}
