@import '@farol-ds/tokens/index.scss';

/*
 * TopbarUserMenuTrigger - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/Farol-DS---%5BWeb%5D-Core-Components?node-id=2427%3A32083&t=F0jPrZtuBGRYhKCc-0
*/

.userMenu {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: var(--fds-spacing-4xs);
  height: 100%;
  // reset button
  border-radius: var(--fds-border-radius-sm);
  background-color: transparent;
  border: 0;
  padding: 0;
  box-shadow: none;
  color: inherit;
}

.skel {
  &Avatar {
    cursor: pointer;
  }
}

@media (min-width: $breakpoint-md) {
  .anonyMenu {
    display: none;
  }
}
