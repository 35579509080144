@import '@farol-ds/tokens/index.scss';

.content {
  display: flex;
  flex-flow: column;
  min-width: 100%;
  min-height: 100%;
}

.summaryGenerationInfoCard {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--fds-spacing-6xs);
}

.summaryGenerationInfoIcon {
  align-items: center;
  display: flex;
  width: 16px;
  height: 16px;
}

.generatingModal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: var(--fds-spacing-3xs);
}

.title {
  margin-bottom: var(--fds-spacing-5xs);
  margin-top: var(--fds-spacing-3xs);
}

.label {
  padding: 0 var(--fds-spacing-xs);
}

.actionBtn {
  margin-top: var(--fds-spacing-3xs);
}

@media screen and (min-width: $breakpoint-sm) {
  .content {
    min-height: min(90vh, 720px);
    width: 540px;
    height: 576px;
  }

  .label {
    padding: 0 var(--fds-spacing-3xl);
  }

  .actionBtn {
    display: none;
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .content {
    min-height: min(85vh, 720px);
  }
}

@media screen and (min-width: $breakpoint-xl) {
  .content {
    min-height: min(75vh, 720px);
  }
}

.footer {
  display: block;
  width: 100%;
}
