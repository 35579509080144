/*
 * SnippetContent - Farol DS
 * https://www.figma.com/file/QmFJQRbQfkP6Upr9TNreoO/%5B01%5D-Farol-DS---Core-Components-%5BWeb%5D?type=design&node-id=4535-11220&mode=design&t=ETTkviXdl3WWicIo-11
*/

/*
 * 1 - it's required to align the chip with first line of the text (assuming that first child of children [unknown] is a paragraph)
*/

.root {
  &:not(a) {
    color: var(--fds-color-base-low-pure);
  }

  & > p:not(:last-child) {
    margin-bottom: var(--fds-spacing-3xs);
  }

  strong,
  b {
    font-weight: var(--fds-font-weight-bold);
  }
}

/*
 * [FIXME] This is a workaround for iOS 16 bug with unexpected min-height calculation.
 * In iOS 17, it was fixed but we need to keep this workaround for backward compatibility.
 * Explain: this class is used to force the min-height of the container to be at least 1 line of text,
 * avoiding hidden content in the snippet.
 * Line height is 24px
 * More context:
 * - https://jusbrasil.slack.com/archives/C04UM58HVCL/p1715355856369989
 * - https://jusbrasil.slack.com/archives/C06FQCA6RS9/p1722442374492719
 * @andersonba
*/
.truncateContainer {
  min-height: 24px;
}

.chip {
  float: left;
  margin-top: -1px; /* 1 */
  margin-right: var(--fds-spacing-5xs);
}
