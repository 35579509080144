/*
 * PopoverContent - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3431-135266&amp;t=Die319SGxkzYFN1o-0
*/

.root {
  position: relative;
  animation-duration: var(--fds-duration-fast-2);
  animation-timing-function: var(--fds-ease-productive);
  background-color: var(--fds-color-base-high-pure);
  border: var(--fds-border-width-sm) solid var(--fds-color-base-high-2);
  border-radius: var(--fds-border-radius-sm);
  box-shadow: var(--fds-shadow-level-2);

  &:not(.disablePadding) {
    padding: var(--fds-spacing-3xs);
  }

  &[data-side='top'] {
    animation-name: slide-up;
  }

  &[data-side='bottom'] {
    animation-name: slide-down;
  }

  &[data-side='left'] {
    animation-name: slide-left;
  }

  &[data-side='right'] {
    animation-name: slide-right;
  }
}

.type {
  &highlight {
    background-color: var(--fds-color-brand-tertiary-pure);
    border-color: var(--fds-color-brand-tertiary-pure);

    .arrow {
      color: var(--fds-color-brand-tertiary-pure);
    }
  }
}

.arrow {
  color: var(--fds-color-base-high-pure);
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(var(--fds-spacing-3xs)) var(--fds-scale-xs);
  }

  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(calc(#{var(--fds-spacing-3xs)} * -1)) var(--fds-scale-xs);
  }

  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(var(--fds-spacing-6xs)) var(--fds-scale-xs);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(calc(#{var(--fds-spacing-6xs)} * -1)) var(--fds-scale-xs);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
