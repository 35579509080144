/*
 * AutocompleteItemRemove - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=3349-128843&amp;t=KcYW6wA6FA8I0CDR-0
*/
@use '../css/mixins.scss' as m;

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  width: 40px;
  border-radius: var(--fds-border-radius-xs);

  transition-property: background-color, box-shadow;
  transition-duration: var(--fds-duration-fast-2);
  transition-timing-function: var(--fds-ease-productive);

  &[aria-current],
  &:hover {
    background-color: var(--fds-color-base-high-2);
  }

  &[aria-current] {
    @include m.focus-style;
  }
}

.icon {
  color: inherit;
}
