@import '@farol-ds/tokens/index.scss';

/*
 * Breadcrumb - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-Web?node-id=2204%3A103104
*/

.root,
.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
}

.ancestorBtn {
  display: none;
}

@media (max-width: $breakpoint-sm) {
  .list {
    display: none;
  }

  .ancestorBtn {
    display: inline-flex;
  }
}
