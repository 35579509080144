@use './variables';
@import '@farol-ds/tokens/index.scss';

/*
 * SearchAutocomplete - Farol DS
 * https://www.figma.com/file/o3PA0D01PIdWAQWBLtcNRd/Farol-DS---Handoff-Core-%5Bweb%5D?type=design&amp;amp;node-id=3368-138082&amp;amp;mode=design&amp;amp;t=Rl6yP2JVhUToaBct-0
*/

.root {
  position: relative;
  width: 100%;
  border-radius: var(--fds-border-radius-md);

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.typePrimary {
  .trigger {
    transition-property: border-radius, background-color;
    transition-duration: var(--fds-duration-fast-1);
    transition-timing-function: var(--fds-ease-productive);
  }

  &[data-focused] {
    height: auto;

    .autocompleteRoot {
      z-index: variables.$z-index-search-autocomplete;
    }

    .trigger {
      outline: none;
      background-color: var(--fds-color-base-high-pure);
      border-radius: 0;

      &,
      &:hover {
        border-color: var(--fds-color-base-high-pure);
      }
    }
  }

  // disable fade out animation when the input is unfocused
  // it's necessary to avoid "black blink" inside the input during the blur transition
  &:not([data-focused]) {
    .overlay {
      transition: none;
    }
  }
}

@media (min-width: $breakpoint-md) {
  .typePrimary[data-focused] {
    .trigger {
      border-radius: var(--fds-border-radius-md);

      &[aria-expanded='true'] {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-color: var(--fds-color-base-high-1);
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .typePrimary,
  .typePrimary .trigger {
    // https://muffinman.io/blog/ios-safari-scroll-position-fixed/
    transform: translate3d(0, 0, 0);
  }
  .typePrimary[data-focused] {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: auto;
    z-index: variables.$z-index-search-autocomplete;

    .autocompleteRoot {
      height: 100%;
      max-height: 100%;
      background-color: var(--fds-color-base-high-pure);
    }

    .trigger {
      padding-left: variables.$back-icon-area-size;

      &,
      &:hover {
        border-bottom: var(--fds-border-width-sm) solid var(--fds-color-base-high-1);
      }

      &LeftIcon {
        display: flex;
      }
    }
  }
}
