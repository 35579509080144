:root {
  color-scheme: light;
  --fds-border-radius-xs: 6px;
  --fds-border-radius-sm: 8px;
  --fds-border-radius-md: 16px;
  --fds-border-radius-lg: 24px;
  --fds-border-radius-pill: 500px;
  --fds-border-radius-circle: 50%;
  --fds-border-width-sm: 1px;
  --fds-border-width-md: 2px;
  --fds-border-width-lg: 4px;
  --fds-breakpoint-xs: 0;
  --fds-breakpoint-sm: 768px;
  --fds-breakpoint-md: 1024px;
  --fds-breakpoint-lg: 1280px;
  --fds-breakpoint-xl: 1440px;
  --fds-color-base-low-1: #2B3950;
  --fds-color-base-low-2: #455468;
  --fds-color-base-low-3: #5C6F8A;
  --fds-color-base-low-4: #788BA5;
  --fds-color-base-low-pure: #0F172A;
  --fds-color-base-high-1: #F1F5F9;
  --fds-color-base-high-2: #E2E8F0;
  --fds-color-base-high-3: #CCD5E1;
  --fds-color-base-high-4: #B3C0D0;
  --fds-color-base-high-pure: #FFFFFF;
  --fds-color-brand-primary-1: #E6F4F0;
  --fds-color-brand-primary-2: #C9E9DF;
  --fds-color-brand-primary-3: #00664F;
  --fds-color-brand-primary-4: #004D3B;
  --fds-color-brand-primary-pure: #007A5F;
  --fds-color-brand-secondary-1: #F2F8EC;
  --fds-color-brand-secondary-2: #D9EBC6;
  --fds-color-brand-secondary-3: #719F41;
  --fds-color-brand-secondary-4: #527231;
  --fds-color-brand-secondary-pure: #7AB441;
  --fds-color-brand-tertiary-1: #E7F5FD;
  --fds-color-brand-tertiary-2: #9BCDFF;
  --fds-color-brand-tertiary-3: #0077C0;
  --fds-color-brand-tertiary-4: #005D95;
  --fds-color-brand-tertiary-pure: #0474E2;
  --fds-color-feedback-negative-1: #FEE2E3;
  --fds-color-feedback-negative-2: #FDBAC5;
  --fds-color-feedback-negative-3: #C20A28;
  --fds-color-feedback-negative-4: #96081F;
  --fds-color-feedback-negative-pure: #E41E3F;
  --fds-color-feedback-positive-1: #F3F8ED;
  --fds-color-feedback-positive-2: #DBEACB;
  --fds-color-feedback-positive-3: #669337;
  --fds-color-feedback-positive-4: #4F722A;
  --fds-color-feedback-positive-pure: #98CC60;
  --fds-color-feedback-info-1: #EBF5FF;
  --fds-color-feedback-info-2: #9BCDFF;
  --fds-color-feedback-info-3: #0467C9;
  --fds-color-feedback-info-4: #0353A2;
  --fds-color-feedback-info-pure: #0474E2;
  --fds-color-feedback-warning-1: #FEFAEC;
  --fds-color-feedback-warning-2: #FDF0BB;
  --fds-color-feedback-warning-3: #E3B806;
  --fds-color-feedback-warning-4: #826A03;
  --fds-color-feedback-warning-pure: #FBD94C;
  --fds-c-brand-text-color-positive: #595B63;
  --fds-c-brand-text-color-negative: #FFFFFF;
  --fds-c-overlay-background: #0F172A;
  --fds-extra-color-visited: #551A8B;
  --fds-extra-focus-outline: 2px solid rgba(4, 116, 226, 0.8);
  --fds-extra-focus-outline-offset: 2px;
  --fds-duration-fast-1: 80ms;
  --fds-duration-fast-2: 160ms;
  --fds-duration-medium-1: 240ms;
  --fds-duration-medium-2: 320ms;
  --fds-duration-slow-1: 480ms;
  --fds-duration-slow-2: 720ms;
  --fds-ease-productive: cubic-bezier(0, 0, 0.3, 1);
  --fds-ease-expressive: cubic-bezier(0.4, 0.12, 0.4, 1);
  --fds-ease-loop: cubic-bezier(0, 0, 1, 1);
  --fds-rotate-xs: rotate(45deg);
  --fds-rotate-sm: rotate(90deg);
  --fds-rotate-md: rotate(180deg);
  --fds-rotate-lg: rotate(270deg);
  --fds-rotate-xl: rotate(360deg);
  --fds-scale-xs: scale(0.92);
  --fds-scale-sm: scale(0.96);
  --fds-scale-md: scale(1.04);
  --fds-scale-lg: scale(1.08);
  --fds-scale-xl: scale(1.16);
  --fds-opacity-level-1: 0.8;
  --fds-opacity-level-2: 0.64;
  --fds-opacity-level-3: 0.32;
  --fds-opacity-level-4: 0.16;
  --fds-opacity-level-5: 0.08;
  --fds-shadow-level-1: 0 2px 4px 0 rgba(25, 52, 102, 0.04);
  --fds-shadow-level-2: 0 2px 8px 0 rgba(25, 52, 102, 0.08);
  --fds-shadow-level-3: 0 4px 24px 0 rgba(25, 52, 102, 0.08);
  --fds-shadow-level-4: 0 8px 40px 0 rgba(25, 52, 102, 0.08);
  --fds-shadow-level-5: 0 16px 56px 0 rgba(25, 52, 102, 0.08);
  --fds-spacing-6xs: 4px;
  --fds-spacing-5xs: 8px;
  --fds-spacing-4xs: 12px;
  --fds-spacing-3xs: 16px;
  --fds-spacing-2xs: 24px;
  --fds-spacing-xs: 32px;
  --fds-spacing-sm: 40px;
  --fds-spacing-md: 48px;
  --fds-spacing-lg: 56px;
  --fds-spacing-xl: 64px;
  --fds-spacing-2xl: 80px;
  --fds-spacing-3xl: 96px;
  --fds-spacing-4xl: 120px;
  --fds-spacing-5xl: 160px;
  --fds-spacing-6xl: 200px;
  --fds-font-family-base: Inter,system-ui,-apple-system,'Segoe UI',Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
  --fds-font-family-variant: Georgia,'Times New Roman',Times,serif;
  --fds-font-size-4xs: 10px;
  --fds-font-size-3xs: 12px;
  --fds-font-size-2xs: 14px;
  --fds-font-size-xs: 16px;
  --fds-font-size-sm: 18px;
  --fds-font-size-md: 20px;
  --fds-font-size-lg: 24px;
  --fds-font-size-xl: 28px;
  --fds-font-size-2xl: 32px;
  --fds-font-size-3xl: 40px;
  --fds-font-size-4xl: 48px;
  --fds-font-size-5xl: 56px;
  --fds-font-size-6xl: 68px;
  --fds-font-weight-bold: 700;
  --fds-font-weight-semibold: 600;
  --fds-font-weight-regular: 400;
  --fds-letter-spacing-sm: -0.4px;
  --fds-letter-spacing-md: 0.4px;
  --fds-line-height-xs: 1;
  --fds-line-height-sm: 1.3;
  --fds-line-height-md: 1.5;
  --fds-line-height-lg: 1.7;
}

[data-fds-theme="dark"] {
  color-scheme: dark;
  --fds-color-base-low-1: #EFF0F0;
  --fds-color-base-low-2: #CFD1D3;
  --fds-color-base-low-3: #AFB2B6;
  --fds-color-base-low-4: #979BA1;
  --fds-color-base-low-pure: #FFFFFF;
  --fds-color-base-high-1: #292B2E;
  --fds-color-base-high-2: #333538;
  --fds-color-base-high-3: #3F4246;
  --fds-color-base-high-4: #4B4E53;
  --fds-color-base-high-pure: #1B1C1E;
  --fds-color-brand-primary-1: #00291F;
  --fds-color-brand-primary-2: #004233;
  --fds-color-brand-primary-3: #00B88F;
  --fds-color-brand-primary-4: #00E0AF;
  --fds-color-brand-primary-pure: #00A37F;
  --fds-color-brand-secondary-1: #293919;
  --fds-color-brand-secondary-2: #4B692B;
  --fds-color-brand-secondary-3: #99C969;
  --fds-color-brand-secondary-4: #B5D991;
  --fds-color-brand-secondary-pure: #8CC256;
  --fds-color-brand-tertiary-1: #012950;
  --fds-color-brand-tertiary-2: #023B73;
  --fds-color-brand-tertiary-3: #4BA4FC;
  --fds-color-brand-tertiary-4: #7ACAFF;
  --fds-color-brand-tertiary-pure: #2390FB;
  --fds-color-feedback-negative-1: #3A030C;
  --fds-color-feedback-negative-2: #660515;
  --fds-color-feedback-negative-3: #FCA1B0;
  --fds-color-feedback-negative-4: #FECDD5;
  --fds-color-feedback-negative-pure: #E94964;
  --fds-color-feedback-positive-1: #293C16;
  --fds-color-feedback-positive-2: #4B6C28;
  --fds-color-feedback-positive-3: #A7D478;
  --fds-color-feedback-positive-4: #C2E1A2;
  --fds-color-feedback-positive-pure: #98CC60;
  --fds-color-feedback-info-1: #012950;
  --fds-color-feedback-info-2: #023B73;
  --fds-color-feedback-info-3: #5FAEFC;
  --fds-color-feedback-info-4: #91C7FD;
  --fds-color-feedback-info-pure: #379AFB;
  --fds-color-feedback-warning-1: #473A00;
  --fds-color-feedback-warning-2: #725D03;
  --fds-color-feedback-warning-3: #FCE173;
  --fds-color-feedback-warning-4: #FDECA5;
  --fds-color-feedback-warning-pure: #FBD94B;
  --fds-c-brand-text-color-positive: #FFFFFF;
  --fds-c-brand-text-color-negative: #595B63;
  --fds-c-overlay-background: #3F4246;
  --fds-extra-color-visited: #A666E1;
}
