@import '@farol-ds/tokens/index.scss';

.options {
  display: flex;
  flex-wrap: wrap;
  gap: var(--fds-spacing-5xs);
  margin-top: var(--fds-spacing-5xs);
}

@media screen and (max-width: $breakpoint-sm) {
  .options {
    flex-direction: column;
    align-items: flex-start;
  }
}
