@import '@farol-ds/tokens/index.scss';

.container {
  display: flex;
  flex-direction: column;

  ul {
    flex-grow: 1;
    margin-bottom: var(--fds-spacing-3xs);
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--fds-spacing-3xs);

  li:empty {
    display: none;
  }
}

.retryText {
  margin-bottom: var(--fds-spacing-3xs);
  text-align: center;
}

.retry {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner {
  margin-top: var(--fds-spacing-3xs);
  display: flex;
  justify-content: center;
}

@media (max-width: $breakpoint-sm) {
  .list {
    gap: var(--fds-spacing-4xs);
  }
}
